import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText'
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { Eye , EyeOff } from 'react-feather'
import { senhavalidate } from '../validates/ValidateForm'

// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
export default function InputPassword(props) {
    
    const [error, setError] = useState()

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });
    
      const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
        props.pegadados({ name: 'password', value: event.target.value })
        if (senhavalidate(event.target.value)) {
          setError(senhavalidate(event.target.value))
        } else {
          setError(senhavalidate(false))
        }
      }
    
      const handleClickShowPassword = () => {
        setValues({
          ...values,
          showPassword: !values.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      return (
    <FormControl  
    variant="outlined"
   
    >
          <OutlinedInput
           style={{backgroundColor: "#fff", borderRadius: '5px'}}
            size="small"
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            error={props.error}
            placeholder='Por favor digite sua senha'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <EyeOff/> : <Eye/>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText style={{ color: 'red' , marginLeft: '0px'}}>
          {props.error}
        </FormHelperText>
        </FormControl>
      
  )
}
