import coming_soon from "../../../../assets/home/coming-soon.svg";

import { Container, Content } from "./style";

export default function NotAuthorized() {
  return (
    <Container>
      <Content>
        <img alt='img' src={coming_soon} width={280} />
        <h1>Conta com restrição! 🔐</h1>
        <h3>Para maiores informações entre em contato com a nossa equipe.</h3>
      </Content>
    </Container>
  );
}
