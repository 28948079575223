import styled from "styled-components";

export const Container = styled.div`
  background-color: #fafafa;
  width: 100%;
  height: 100%;
`;

export const Button = styled.div`
  width: 100%;
  padding: 0px 0px 0px 0px;
`;

export const Modals = styled.div`
  width: 100%;
  padding: 20px 0px 20px 0px;
`;

export const DivLoading = styled.div`
  display: flex;
  justify-content: center;
`;

export const DivIcons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: auto;
`;

export const ContainerInformation = styled.div`
  margin-top: 20px;
  width: 100%;
`;

