import React, { useState, useEffect, useContext } from "react";

import PrimaryButton from "../../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import { Collapse } from "@mui/material";
import api from "../../../../Services/index";
import { RegisterContext } from "../../../../Utility/Context/RegisterContext";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Container,
  Form,
  Button,
  Fields,
  FieldsAndButton,
  Alert,
  DivLoading,
} from "./style";

export default function BillingData() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [addressId, setAddressId] = useState(0);
  const [checkAddresses, setCheckAddresses] = useState();

  async function handleGetInfo() {
    try {
      setLoading(true);
      const response = await api.get("/api/user/address");
      setCheckAddresses(response.data.user_addresses.length);
      setAddressId(response.data.user_addresses[0].id);
      setCep(response.data.user_addresses[0].zip_code);
      setAddress(response.data.user_addresses[0].street);
      setNeighborhood(response.data.user_addresses[0].neighborhood);
      setNumber(response.data.user_addresses[0].number);
      setComplement(response.data.user_addresses[0].complement);
      setCity(response.data.user_addresses[0].city);
      setState(response.data.user_addresses[0].state);
    } catch (err) { }
    setLoading(false);
  }

  function bodyValidated() {
    setDisabled(true);
    if (
      cep !== "" &&
      address !== "" &&
      neighborhood !== "" &&
      number !== "" &&
      complement !== "" &&
      city !== "" &&
      state !== ""
    ) {
      setDisabled(false);
    }
  }

  useEffect(() => {
    bodyValidated();
  }, [cep, address, neighborhood, complement, city, state]);

  const searchPostalCode = async (postalCode) => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        setCep(data.cep);
        setAddress(data.logradouro);
        setNeighborhood(data.bairro);
        setCity(data.localidade);
        setState(data.uf);
      });
  };

  async function handleUpdateInfo() {
    try {
      setLoading(true);
      if (checkAddresses !== 0) {
        const response = await api.put(
          `/api/user/address/update/${addressId}`,
          {
            address_id: addressId,
            zip_code: cep?.replace(/[^0-9s]/g, ""),
            state,
            city,
            street: address,
            number,
            neighborhood,
            complement,
            type_address: "principal",
          }
        );

        if (response) {
          setAlertType("success");
          setShowAlertOk(true);
          setLoading(false);
        }
      } else {
        const response = await api.post(`/api/user/address/register`, {
          zip_code: cep?.replace(/[^0-9s]/g, ""),
          state,
          city,
          street: address,
          number,
          neighborhood,
          complement,
          type_address: "principal",
        });

        if (response) {
          setAlertType("success");
          setShowAlertOk(true);
          setLoading(false);
          handleGetInfo();
        }
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  useEffect(() => {
    cep.length === 8 && searchPostalCode();
  }, [cep]);

  useEffect(() => {
    handleGetInfo();
  }, []);

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <>
      {loading ? (
        <Container>
          <DivLoading>
            <CircularProgress style={{ marginTop: "30px" }} />
          </DivLoading>
        </Container>
      ) : (
        <Container>
          <Form>
            <label id="cep">CEP</label>

            <TextField
              type="tel"
              id="cep"
              size="small"
              value={cep}
              onChange={(e) => {
                setCep(e.target.value);
              }}
            />
            <label id="endereco">Endereço</label>
            <TextField
              id="endereco"
              type="text"
              size="small"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Fields>
                <label id="bairro">Bairro</label>
                <TextField
                  id="bairro"
                  type="text"
                  size="small"
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
              </Fields>
              <Fields style={{ marginLeft: "15px" }}>
                <label id="numero">Número</label>
                <TextField
                  id="numero"
                  type="number"
                  size="small"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Fields>
            </div>
            <label id="complemento">Complemento</label>
            <TextField
              size="small"
              id="complemento"
              type="text"
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
            <FieldsAndButton>
              <Fields>
                <label id="cidade">Cidade</label>
                <TextField
                  id="cidade"
                  type="text"
                  size="small"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Fields>
              <Fields style={{ marginLeft: "15px" }}>
                <label id="estado">Estado</label>
                <TextField
                  id="estado"
                  type="text"
                  size="small"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Fields>
            </FieldsAndButton>
            <Button>
              <PrimaryButton
                text="Atualizar informações"
                funcao={handleUpdateInfo}
                disabled={disabled}
              />
            </Button>
          </Form>
          <Alert>
            <CustomAlert
              type={alertType}
              alertText={
                alertType === "success"
                  ? "Informações atualizadas com sucesso!"
                  : "Erro ao atualizar informações!"
              }
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />
          </Alert>
        </Container>
      )}
    </>
  );
}
