import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 20%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  h1 {
    margin-top: 20px;
    text-align: center;
    line-height: 35px;
  }

  h3 {
    margin-top: 20px;
    text-align: center;
  }

  h4 {
    margin-top: 10px;
    text-align: center;
  }
`;
