import React, { useState } from "react";
import { Container, Form, Button, Alert, Image } from "./style";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../../components/PrimaryButton";
import api from "../../../../Services/index";
import CircularProgress from "@mui/material/CircularProgress";
import { MdModeEditOutline } from "react-icons/md";
import ModalExitDependent from "../../../../components/ModalExitDependent";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import logo_default from "../../../../assets/logo_default.png";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import Telefone from "../../../Inputs/telephone";
export default function EditDependents({ setStep }) {
  const [loadFoto, setLoadFoto] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const [avatar, setAvatar] = useState("");
  const { ModalExitIsTrue } = StateGlobal();
  const { dependentInfo, setDependentInfo } = StateGlobal();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);

  async function HandleSubmit() {
    try {
      const response = await api.put(
        `/api/user/dependent/update/${dependentInfo.id}`,
        {
          name: dependentInfo.name,
          phone: dependentInfo.phone?.replace(/[^0-9s]/g, ""),
          birthday: newDate,
          cpf: dependentInfo.cpf?.replace(/[^0-9s]/g, ""),
          whatsapp: "",
          rg: "",
          photo: "",
          genre: "",
          schooling: "",
          holder_income: "",
          working_department: "",
          marital_status: "",
          spouse_name: "",
          spouse_birthday: "",
          mother_birthday: "",
          father_name: "",
          how_meet: dependentInfo.how_meet,
        }
      );
      if (response.data) {
        setAlertType("success");
        setShowAlertOk(true);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  const options = [
    {
      value: "empresarial",
      label: "Seu trabalho",
    },
    {
      value: "facebook",
      label: "Mídias Sociais",
    },
    {
      value: "google",
      label: "Google",
    },
    {
      value: "fachada_doutor_saude",
      label: "Outdoor",
    },
    {
      value: "indicacao",
      label: "Indicação",
    },
    {
      value: "indicacao_premiada",
      label: "Indicação Premiada",
    },
    {
      value: "outro_cartao_doutor_saude",
      label: "Outro Cartão",
    },
    {
      value: "panfleto",
      label: "Panfleto",
    },
    {
      value: "pessoalmente",
      label: "Pessoalmente",
    },
    {
      value: "reativacao",
      label: "Reativação do Plano",
    },
    {
      value: "site",
      label: "Nosso Site",
    },
    {
      value: "telefone",
      label: "Por telefone",
    },
    {
      value: "venda_lista",
      label: "Lista Telefônica",
    },
    {
      value: "clinicas_reunidas",
      label: "Clínicas Parceiras",
    },
    {
      value: "centro_medico",
      label: "Centro Médico Sorocaba",
    },
  ];

  function getBase64(file) {
    if (file.size > 10000000) {
      alert(
        "Documento maior que 10MB, por favor verifique o tamanho do arquivo."
      );
      return;
    }

    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      putPhotoPerfil(reader.result);
    };
  }

  async function putPhotoPerfil(image) {
    setLoadingImg(true);
    setLoadFoto(true);
    await api
      .post("/api/user/picture", {
        photo: image,
      })
      .then((resp) => {
        if (resp?.data.error) {
          setAvatar(resp.data.picture_url);

          setLoadFoto(false);
          setLoadingImg(false);
        }
      })
      .catch((error) => {
        setLoadingImg(false);
      });
  }

  function maskCpf(v) {
    v = v?.replace(/\D/g, "");

    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    return v;
  }

  function dateMask(value) {
    value = value?.replace(/\D/g, "");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");

    return value;
  }

  var newDate = dependentInfo.birthday.split("/").reverse().join("-");
  return (
    <Container>
      <div id="sub-container">
        <Image>
          <img
            src={avatar !== "" ? avatar : logo_default}
            width={85}
            alt="profileImg"
          />
        </Image>
        <div id="div-pen">
          <label htmlFor="addFoto">
            {loadFoto ? (
              <div style={{ paddingTop: "2px" }}>
                <CircularProgress size={20} style={{ color: "#fff" }} />
              </div>
            ) : (
              <MdModeEditOutline fill="#fff" />
            )}
            <input
              id="addFoto"
              type="file"
              hidden
              onChange={(event) => getBase64(event.target.files[0])}
            />
          </label>
        </div>
      </div>
      <Form>
        <label id="name">Nome Completo</label>
        <TextField
          id="name"
          type="text"
          size="small"
          placeholder="Digite seu nome completo"
          value={dependentInfo.name}
          onChange={(e) => {
            setDependentInfo({ ...dependentInfo, name: e.target.value });
          }}
        />
        <label id="telefone">Telefone de Contato</label>
        <TextField
          id="telefone"
          type="text"
          size="small"
          placeholder="Digite seu telefone"
          value={dependentInfo.phone ? Telefone(dependentInfo.phone) : ""}
          inputProps={{ maxLength: 15 }}
          onChange={(e) => {
            setDependentInfo({ ...dependentInfo, phone: e.target.value });
          }}
        />

        <label id="email">E-mail</label>
        <TextField
          id="email"
          type="text"
          size="small"
          placeholder="Digite seu melhor e-mail"
          value={dependentInfo.email}
          disabled={true}
        />
        <label id="cpf">CPF</label>
        <TextField
          id="cpf"
          type="text"
          size="small"
          placeholder="Digite seu CPF"
          value={dependentInfo.cpf ? maskCpf(dependentInfo.cpf) : ""}
          inputProps={{ maxLength: 14 }}
          onChange={(e) => {
            setDependentInfo({ ...dependentInfo, cpf: e.target.value });
          }}
        />

        <label id="nascimento">Data de Nascimento</label>
        <TextField
          size="small"
          id="nascimento"
          placeholder="Digite sua Data de Nascimento"
          value={dateMask(dependentInfo.birthday)}
          onChange={(e) => {
            setDependentInfo({ ...dependentInfo, birthday: e.target.value });
          }}
        />

        <label id="meet">Como ficou sabendo do Dr. Saúde</label>
        <Select
          id="meet"
          size="small"
          placeholder="Selecione uma opção"
          style={{ marginBottom: "25px" }}
          value={dependentInfo.how_meet}
          onChange={(e) => {
            setDependentInfo({ ...dependentInfo, how_meet: e.target.value });
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Form>
      <Button>
        <PrimaryButton text="Atualizar Informações" funcao={HandleSubmit} />
      </Button>
      {/* <Button>
        <OutlinedButtonBlue text="Voltar" />
      </Button> */}
      {ModalExitIsTrue === true ? (
        <ModalExitDependent
          open={ModalExitIsTrue}
          HandleSubmit={HandleSubmit}
        />
      ) : (
        ""
      )}
      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Dados atualizadas com sucesso!"
              : "Erro ao atualizar dados!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
    </Container>
  );
}
