import React, { useEffect, useState } from "react";
import CurrentBalance from "./CurrentBalance/CurrentBalance";
import logo_default from "../../../../assets/logo_default.png";
import { Menu } from "react-feather";
import conexao from "../../../../Services/index";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import { mcc } from "../../../../components/validates/Validate";

import {
  Container,
  SubContainer,
  Icon,
  ContainerInfo,
  Info,
  AvatarLogo,
  Saldo,
  ProfileImageBlocked,
} from "./style";

export default function Headerhome(props) {
  const { dataClient, setDataClient, auxMoney } = StateGlobal();

  async function getData() {
    props?.setLoad(true);
    const response = await conexao.get(`api/user`);
    try {
      setDataClient(response.data);
    } catch (err) {
      setDataClient("Não foi possível carregar...");
    } finally {
      props?.setLoad(false);
    }
  }

  useEffect(() => {
    getData();
  }, [auxMoney]);

  return (
    <Container props={props.position}>
      <SubContainer>
        <Icon
          onClick={() => {
            props.open();
          }}
        >
          <Menu
            color="#08559F"
            strokeWidth="3"
          />
        </Icon>
        <ContainerInfo>
          <Info>
            <p>{dataClient.name ? dataClient.name : "Carregando..."}</p>
            <p id="card">
              Cartão:{" "}
              {dataClient.card_number
                ? mcc(dataClient.card_number)
                : "Carregando..."}
            </p>
          </Info>
          {dataClient.block === "sem_bloqueio" ? (
            <AvatarLogo>
              <img
                alt="Avatar"
                src={dataClient.photo ? dataClient.photo : logo_default}
              ></img>
            </AvatarLogo>
          ) : props.load === false ? (
            <ProfileImageBlocked>
              <img
                alt="Avatar"
                src={dataClient.photo ? dataClient.photo : logo_default}
              ></img>
              <p>restrição</p>
            </ProfileImageBlocked>
          ) : (
            <AvatarLogo>
              <img
                alt="Avatar"
                src={dataClient.photo ? dataClient.photo : logo_default}
              ></img>
            </AvatarLogo>
          )}
        </ContainerInfo>
      </SubContainer>
      <Saldo>
        <CurrentBalance />
      </Saldo>
    </Container>
  );
}
