import React, { useState, useContext } from "react";
import Historico from "../../../../assets/home/Historico.png";
import Agendamento from "../../../../assets/home/Agendamento.png";
import Exames from "../../../../assets/home/Exames.png";
import Recarregar from "../../../../assets/home/Recarregar.png";
import { Container } from "./styled";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";

export default function Footerhome(props) {
  const { components, setComponents, dataClient } = StateGlobal();

  return (
    <>
      {props?.load ? (
        ""
      ) : (
        <Container>
          <div
            onClick={() => {
              setComponents(0);
            }}
          >
            <img
              alt="icon"
              src={Historico}
            />
            <p>Histórico</p>
          </div>
          <div
            onClick={() => {
              setComponents(1);
            }}
          >
            <img
              alt="icon"
              src={Agendamento}
            />
            <p>Agenda</p>
          </div>
          <div
            onClick={() => {
              setComponents(2);
            }}
          >
            <img
              alt="icon"
              src={Exames}
            />
            <p>Exames</p>
          </div>
          {dataClient.tipo_cartao === "pre" ? (
            <div
              onClick={() => {
                setComponents(3);
              }}
            >
              <img
                alt="icon"
                src={Recarregar}
              />
              <p>Recarregar</p>
            </div>
          ) : (
            ""
          )}
        </Container>
      )}
    </>
  );
}
