import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ValidatorCpf from "../../../../components/Inputs/cpf";
import { MdExpandMore } from "react-icons/md";
import ModalDeleteDependent from "../../../../components/ModalDeleteDependent";
import TrashIcon from "../../../../assets/components/trash_icon.png";
import EditIcon from "../../../../assets/components/edit_icon.png";
import ModalSuccesDeleteDependent from "../../../../components/ModalSuccesDeleteDependent";
import { useNavigate } from "react-router-dom";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import { DivIcons } from "./style";
export default function Modal({
  id,
  name,
  email,
  phone,
  rg,
  cpf,
  birthday,
  addresses,
  how_meet,
  whatsapp,
  situation,
}) {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const { dependentInfo, setDependentInfo } = StateGlobal();
  const navigate = useNavigate();

  function ViewModal() {
    setDependentInfo({
      id: id,
      name: name,
      email: email,
      phone: phone,
      rg: rg,
      cpf: cpf,
      birthday: birthday,
      how_meet: how_meet,
      addresses: addresses,
      whatsapp: whatsapp,
      situation: situation,
    });
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  function CloseModal() {
    if (open) {
      setOpen(false);
    }
    if (openSecondModal) {
      setOpenSecondModal(false);
    }
  }

  function ViewSecondModal() {
    setOpen(false);
    if (openSecondModal === false) {
      setOpenSecondModal(true);
    }
  }

  function handleEditDependent() {
    setDependentInfo({
      id: id,
      name: name,
      email: email,
      phone: phone,
      rg: rg,
      cpf: cpf,
      birthday: birthday,
      how_meet: how_meet,
      addresses: addresses,
      whatsapp: whatsapp,
      situation: situation,
    });
    navigate("/edit/dependent");
  }

  return (
    <div>
      <Accordion style={{ marginTop: "2px", borderRadius: "0px" }}>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={(e) => {}}
        >
          <Typography
            style={{
              fontSize: "14px",
              color: "#5E5873",
              fontWeight: "600",
            }}
          >
            Nome: {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            style={{
              fontSize: "14px",
              color: "#5E5873",
              fontWeight: "500",
            }}
          >
            CPF: {ValidatorCpf(cpf)}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              color: "#5E5873",
              fontWeight: "500",
            }}
          >
            Data Nascimento: {birthday}
          </Typography>
          {situation === "inativo" ? (
            ""
          ) : (
            <>
              <DivIcons>
                <span
                  style={{
                    padding: "3px 4px",
                    backgroundColor:
                      situation === "ativo"
                        ? "#2EDE6A"
                        : situation === "inativo"
                        ? "#EDDE5A"
                        : situation === "aguardando_aprovacao"
                        ? "#00CFE8"
                        : "",
                    borderRadius: "10px",
                    width: "150px",
                    color: "#FFF",
                    fontSize: "12px",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                    marginRight: "8px",
                    alignItems: "center",
                  }}
                >
                  Cadastro:{" "}
                  {situation === "aguardando_aprovacao"
                    ? "Aguardando"
                    : situation}
                </span>
                {situation === "ativo" ? (
                  <div>
                    <img
                      src={EditIcon}
                      alt="editIcon"
                      width={30}
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        handleEditDependent();
                      }}
                    />
                    <img
                      src={TrashIcon}
                      alt="trashIcon"
                      width={30}
                      onClick={() => {
                        ViewModal();
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </DivIcons>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {open === true && (
        <ModalDeleteDependent
          open={open}
          funcao={ViewModal}
          CloseModal={CloseModal}
          ViewSecondModal={ViewSecondModal}
        />
      )}
      {openSecondModal === true && (
        <ModalSuccesDeleteDependent
          CloseModal={CloseModal}
          open={openSecondModal}
        />
      )}
    </div>
  );
}
