import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;

  #sub-container {
    display: flex;
    align-items: flex-end;
  }

  #div-pen {
    background-color: #51c6d6;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    margin-left: -30px;
    margin-bottom: 0px;
  }
`;

export const HeaderBar = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 15px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #00000030;

  h3 {
    color: #5e5873;
  }

  p {
    text-decoration: underline;
    color: #08559f;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const DivImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Form = styled.div`
  width: 100%;
  padding: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #name {
    margin-top: 0px;
  }

  label {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 400px;
    color: #333;
    margin-top: 25px;
    margin-bottom: 5px;
  }
`;

export const Button = styled.div`
  width: 100%;
  padding: 20px 00px 0px 00px;
`;

export const Image = styled.div`
  width: 94px;
  height: 94px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
    border: solid 3px #08559f;
    object-fit: cover;
  }
`;

export const Alert = styled.div`
  width: "100%";
  height: "100%";
  display: "flex";
  justify-content: "center";
`;
