import React, { useState, useEffect } from "react";
import {
  Container,
  Content,
  ImageAndTitle,
  Title,
  Circulo,
  CirculoLine,
  LineProgres,
  ContainerProgres,
  ContainerLabelProgres,
  ContainerLabelSpace,
  ContainerLabel,
  LabelText,
  Fields,
  FieldsAndButton,
  Form,
  Button,
  ButtonBack,
  TextButtonBack,
} from "./style";
import PrimaryButton from "../../../../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import { StateGlobal } from "../../../../../../Utility/Context/RegisterContext";
import CardRechargeIcon from "../../../../../../assets/home/CardRechargeIcon.png";
import { api_payhow } from "../../../../../../Services";
import { CircularProgress } from "@mui/material";
import {
  savePublicKey,
  saveCheckoutSession,
  saveCheckoutID,
  getCheckoutID,
} from "../../../../../../Services/auth";
export default function RechargeValue() {
  const {
    dataClient,
    rechargeSteps,
    setRechargeSteps,
    address_info,
    setAddress_info,
    valueRecharged,
    order_id,
  } = StateGlobal();
  const [isOk, setIsOk] = useState(true);
  const [loading, setLoading] = useState(false);
  const client_name_last = dataClient.name.split(" ");
  const searchPostalCode = async (postalCode) => {
    fetch(`https://viacep.com.br/ws/${address_info.zip_code}/json/`)
      .then((response) => response.json())
      .then((data) => {
        setAddress_info({
          ...address_info,
          zip_code: data.cep,
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      });
  };

  useEffect(() => {
    address_info.zip_code?.length === 8 && searchPostalCode();
  }, [address_info.zip_code]);

  function bodyValidate() {
    if (
      address_info.zip_code !== "" &&
      address_info.street !== "" &&
      address_info.neighborhood !== "" &&
      address_info.number !== "" &&
      // address_info.complement !== "" &&
      address_info.city !== "" &&
      address_info.state !== "" &&
      address_info.zip_code !== null &&
      address_info.street !== null &&
      address_info.neighborhood !== null &&
      address_info.number !== null &&
      // address_info.complement !== null &&
      address_info.city !== null &&
      address_info.state !== null &&
      address_info.zip_code !== undefined &&
      address_info.street !== undefined &&
      address_info.neighborhood !== undefined &&
      address_info.number !== undefined &&
      // address_info.complement !== undefined &&
      address_info.city !== undefined &&
      address_info.state !== undefined
    ) {
      setIsOk(false);
    } else {
      setIsOk(true);
    }
  }

  useEffect(() => {
    bodyValidate();
  }, [address_info]);

  async function handleCart() {
    try {
      const checkout_id = getCheckoutID();
      const res = await api_payhow.post("v1/ecommerce/checkout/payhow/data", {
        checkout_id,
      });
      if (res.data.public_key) {
        savePublicKey(res.data.public_key);
        setTimeout(() => {
          handlePublicKey();
        }, 500);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  async function handlePublicKey() {
    try {
      const checkout_id = getCheckoutID();

      fetch(
        `${process.env.REACT_APP_PAYHOW_URL}v1/ecommerce/checkout/${checkout_id}/checkout_session`,
        {
          method: "get",
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : "",
            Accept: "application/json",
            ContentType: "application/json",
            "public-key": localStorage.getItem("public_key"),
            // 'Access-Control-Allow-Origin': '*'
          },
        }
      ).then((response) => {
        response.json().then((checkout_session) => {
          saveCheckoutSession(checkout_session.checkout_session);
          setTimeout(() => {
            handleShipping();
          }, 500);
        });
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  async function handleShipping() {
    try {
      fetch(
        `${process.env.REACT_APP_PAYHOW_URL}v1/ecommerce/checkout/get_shipping`,
        {
          method: "post",
          headers: {
            Authorization: localStorage.getItem("token")
              ? "Bearer " + localStorage.getItem("token")
              : "",
            Accept: "application/json",
            "Content-Type": "application/json",
            "public-key": localStorage.getItem("public_key"),
            "checkout-session": localStorage.getItem("checkout_session"),
            // 'Access-Control-Allow-Origin': '*'
          },

          body: JSON.stringify({
            street: address_info.street,
            number: address_info.number,
            city: address_info.city,
            postal_code: address_info.zip_code?.replace(/\D/g, ""),
            neighborhood: address_info.neighborhood,
            state: address_info.state,
            complement: address_info.complement ? address_info.complement : "",
          }),
        }
      ).then((response) => {
        response.json().then((resShipping) => {
          if (resShipping) {
            validateShipping();
          }
        });
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }

    async function validateShipping() {
      try {
        fetch(
          `${process.env.REACT_APP_PAYHOW_URL}v1/ecommerce/checkout/set_shipping`,
          {
            method: "post",
            headers: {
              Authorization: localStorage.getItem("token")
                ? "Bearer " + localStorage.getItem("token")
                : "",
              Accept: "application/json",
              "Content-Type": "application/json",
              "public-key": localStorage.getItem("public_key"),
              "checkout-session": localStorage.getItem("checkout_session"),
              // 'Access-Control-Allow-Origin': '*'
            },

            body: JSON.stringify({
              shipping_slug: "frete-personalizado",
            }),
          }
        ).then((response) => {
          response.json().then((resShipping) => {
            if (resShipping) {
              setLoading(false);
              setRechargeSteps(3);
            }
          });
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const res = await api_payhow.post("v1/ecommerce/external/cart", {
        access_token: process.env.REACT_APP_ACCESS_TOKEN, 
        order_id,
        cart: "",
        merchant_url: process.env.REACT_APP_PAYHOW_MERCHANT_URL,
        items: [
          {
            id: 1,
            sku: "1",
            name: "Recarga",
            price: valueRecharged,
            width: "1.0",
            height: "1.0",
            images: [
              {
                src: "",
                name: "",
              },
            ],
            length: "1.0",
            weight: "1.0",
            quantity: 1,
            description: "Recarga Dr Saude por cartão de crédito",
            shipping_taxable: false,
            shipping_required: false,
          },
        ],
        user: {
          user_address_1: address_info.street,
          user_address_2: address_info.complement
            ? address_info.complement
            : "",
          user_city: address_info.city,
          user_country: "BR",
          user_email: dataClient.email,
          user_firstname: client_name_last[0],
          user_id: "",
          user_lastname: client_name_last[1] ? client_name_last[1] : "",
          user_login: "",
          user_nicename: "",
          user_postcode: address_info.zip_code?.replace(/[^0-9s]/g, ""),
          user_state: address_info.state,
        },
      });
      if (res.data.cart) {
        saveCheckoutID(res.data.cart);
        setTimeout(() => {
          handleCart();
        }, 500);
      }
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Content>
        <ImageAndTitle>
          <img src={CardRechargeIcon} width={25} alt="icon_schedule" />
          <Title>Recarregar seu Cartão</Title>
        </ImageAndTitle>

        <ContainerProgres>
          <LineProgres>
            <Circulo />
            <Circulo />
            <CirculoLine />
          </LineProgres>
        </ContainerProgres>

        <ContainerLabelProgres>
          <ContainerLabelSpace>
            <ContainerLabel>
              <LabelText>Valor de recarga</LabelText>
            </ContainerLabel>
            <ContainerLabel>
              <LabelText>Dados pessoais</LabelText>
            </ContainerLabel>
            <ContainerLabel>
              <LabelText>Dados de pagamento</LabelText>
            </ContainerLabel>
          </ContainerLabelSpace>
        </ContainerLabelProgres>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "450px",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Form>
              <label id="cep">CEP</label>

              <TextField
                id="cep"
                type="tel"
                size="small"
                inputProps={{ maxLength: 9 }}
                value={address_info.zip_code}
                onChange={(e) => {
                  setAddress_info({
                    ...address_info,
                    zip_code: e.target.value,
                  });
                }}
              />
              <label id="endereco">Endereço</label>
              <TextField
                id="endereco"
                type="text"
                size="small"
                value={address_info.street}
                onChange={(e) => {
                  setAddress_info({ ...address_info, street: e.target.value });
                }}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Fields>
                  <label id="bairro">Bairro</label>
                  <TextField
                    id="bairro"
                    type="text"
                    size="small"
                    value={address_info.neighborhood}
                    onChange={(e) => {
                      setAddress_info({
                        ...address_info,
                        neighborhood: e.target.value,
                      });
                    }}
                  />
                </Fields>
                <Fields style={{ marginLeft: "15px" }}>
                  <label id="numero">Número</label>
                  <TextField
                    id="numero"
                    type="tel"
                    size="small"
                    value={address_info.number}
                    onChange={(e) => {
                      setAddress_info({
                        ...address_info,
                        number: e.target.value,
                      });
                    }}
                  />
                </Fields>
              </div>
              <label id="complemento">Complemento</label>
              <TextField
                size="small"
                id="complemento"
                type="text"
                value={address_info.complement}
                onChange={(e) => {
                  setAddress_info({
                    ...address_info,
                    complement: e.target.value,
                  });
                }}
              />
              <FieldsAndButton>
                <Fields>
                  <label id="cidade">Cidade</label>
                  <TextField
                    id="cidade"
                    type="text"
                    size="small"
                    value={address_info.city}
                    onChange={(e) => {
                      setAddress_info({
                        ...address_info,
                        city: e.target.value,
                      });
                    }}
                  />
                </Fields>
                <Fields style={{ marginLeft: "15px" }}>
                  <label id="estado">Estado</label>
                  <TextField
                    id="estado"
                    type="text"
                    size="small"
                    value={address_info.state}
                    onChange={(e) => {
                      setAddress_info({
                        ...address_info,
                        state: e.target.value,
                      });
                    }}
                  />
                </Fields>
              </FieldsAndButton>
              <Button>
                <PrimaryButton
                  text="Próximo"
                  funcao={handleSubmit}
                  disabled={isOk}
                />
              </Button>
            </Form>
            <ButtonBack
              onClick={() => {
                setRechargeSteps(1);
              }}
            >
              <TextButtonBack>Voltar</TextButtonBack>
            </ButtonBack>
          </>
        )}
      </Content>
    </Container>
  );
}
