import React from "react";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import { Container, Content } from "./style";

import  RechargeValue  from"./steps/recharge_value"
import  PersonalData  from"./steps/personal_data"
import  PaymentData  from"./steps/payment_data"


export default function AutomaticRecharge() {

  const { rechargeSteps , setRechargeSteps } = StateGlobal();


  return (
    <Container>
      <Content>
        {rechargeSteps === 1 ? <RechargeValue/> :
         rechargeSteps === 2 ? <PersonalData/> : 
         rechargeSteps === 3 ? <PaymentData/> : ''}
      </Content>
    </Container>
  );
}
