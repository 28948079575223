import React from "react";
import { StateGlobal } from "../../../../../Utility/Context/RegisterContext";
import {
  Container,
  Date,
  ContainerInfor,
} from "./style";
import Telefone from "../../../../../components/Inputs/telephone";
import { BsCalendar } from "react-icons/bs";
import { FaLocationArrow } from "react-icons/fa";

export default function SchedulingBlock(props) {
  const { setRowId, dataClient, setInfoScheduleById } = StateGlobal();

  return (
    <Container>
      <Date>
        <p id="Day">{props.info.day}</p>
        <p>{props.info.month_text}</p>
        <p>{props.info.year}</p>
        <label id="datePlus">{props.info.hour}</label>
      </Date>

      <ContainerInfor props={props.info.type} status={props.info.status}>
        {/* info value recharge */}
        <div id="TitleStatus">
          <p id="TypeCard">{props.info.title}</p>
          <span>{props.info.status}</span>
        </div>

        {/* info date */}

        <div>
          <p>Clínica Médica - {props.info.partner_name !== null ? props.info.partner_name : 'Aguardando'}  </p>
          <p id="forma">{props.info.partner_phone ? `Telefone: ${Telefone(props.info.partner_phone)}`: ''}</p>
          {props.info.status !== "aguardando" ? (
            <div
              style={{
                display: "flex",
                width: "215px",
                justifyContent: "space-between",
                padding: "3px 0px",
                color: "#08559F",
                fontWeight: "600",
                fontSize: "10px",
                textDecoration: "underline",
              }}
            >
              <div
                style={{ display: "flex" }}
                onClick={() => {
                  props.open();
                  setRowId(props.info.id);
                  setInfoScheduleById(props.info);
                }}
              >
                <p style={{ display: "flex" }}>
                  {" "}
                  <BsCalendar
                    style={{ marginTop: "2px", marginRight: "3px" }}
                  />{" "}
                  Ver agendamento
                </p>
              </div>
              <div>
                <p style={{ display: "flex" }}>
                  {" "}
                  <FaLocationArrow style={{ marginTop: "2px", marginRight: "3px" }}/>
                  Como chegar
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </ContainerInfor>
    </Container>
  );
}
