import React, { useContext, useState } from "react";
import logo from "../../../assets/LogoCadastro.png";

import PrimaryButton from "../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import Telefone from "../../../components/Inputs/telephone";
import { RegisterContext } from "../../../Utility/Context/RegisterContext";
import CustomAlert from "../../../components/Alerts/CustomAlert";

import {
  Container,
  Step1,
  Step2,
  Step3,
  Step4,
  HeaderCadastro,
  Form,
  Button,
  Icon,
  Alert,
} from "./style";

export default function Cadastro() {
  const { register, setRegister } = useContext(RegisterContext);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const navigate = useNavigate();

  function handleNextPage() {
    if (
      register.name !== "" &&
      register.telephone !== "" &&
      register.email !== ""
    ) {
      navigate("/cadastro/2");
    } else {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <>
      <Container>
        <HeaderCadastro>
          <Icon>
            <ChevronLeft
              color="#FFF"
              onClick={() => {
                navigate("/");
              }}
            />
          </Icon>
          <img src={logo} alt="logo" width={120} height={80} />
          <p>Cadastro</p>
          <div>
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
          </div>
        </HeaderCadastro>
        <Form>
          <label id="name">Nome Completo</label>
          <TextField
            id="name"
            type="text"
            size="small"
            placeholder="Por favor digite seu nome completo"
            value={register.name}
            onChange={(e) => setRegister({ ...register, name: e.target.value })}
          />
          <label id="telefone">Telefone de Contato</label>
          <TextField
            id="telefone"
            size="small"
            placeholder="Digite seu telefone para contato"
            inputProps={{ maxLength: 15 }}
            value={Telefone(register.telephone)}
            onChange={(e) => {
              setRegister({ ...register, telephone: e.target.value });
            }}
          />
          <label id="email">E-mail</label>
          <TextField
            id="email"
            type="text"
            size="small"
            placeholder="Digite seu melhor e-mail"
            value={register.email}
            onChange={(e) =>
              setRegister({ ...register, email: e.target.value })
            }
          />
        </Form>
        <Button>
          <PrimaryButton text="Próximo" funcao={handleNextPage} />
        </Button>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={alertType === "error" && "Preencha todos os campos!"}
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
      </Container>
    </>
  );
}
