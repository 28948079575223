import React, { useState } from "react";
import IconMenu from "../../../../../assets/home/iconMenu.png";
import Vector from "../../../../../assets/home/Vector.png";
import { Container, TitleHistory, PositionFilter, PositionBtn } from "./style";
import ModalSchedule from "../../../../../components/ModalFilterSchedule/index";
import icon_schedule from "../../../../../assets/home/icon_schedule.png";
import Agendamento from "../../../../../assets/home/calendarIconWhite.png";
import { StateGlobal } from "../../../../../Utility/Context/RegisterContext";
import { X } from "react-feather";

export default function ScheduleTitle() {
  const [open, setOpen] = useState(false);
  const { components, setComponents } = StateGlobal();
  const [viewClearBtn, setViewClearBtn] = useState(false);
  const { bodyFilterScheduling, setBodyFilterScheduling } = StateGlobal();

  function ViewModal() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  function clearFilter() {
    if (
      bodyFilterScheduling.start !== null ||
      bodyFilterScheduling.start !== "" ||
      bodyFilterScheduling.end !== null ||
      bodyFilterScheduling.end !== ""
    ) {
      setBodyFilterScheduling({
        start: null,
        end: null,
        page: 1,
        send: false,
      });
    }
    setViewClearBtn(false);
  }

  return (
    <>
      <Container>
        <TitleHistory>
          <img alt="icon" src={icon_schedule} />
          <h1>Agenda</h1>
        </TitleHistory>
        
        {bodyFilterScheduling.send === true ? (
            <button
              id="remove"
              onClick={() => {
                clearFilter();
              }}
            >
              <X
                color="#fff"
                size={22}
              />
              remover filtro
            </button>
          ) : (
          <>
          <PositionBtn>
          <button onClick={() => {
            setComponents(1.1)
          }}>
            <img alt="icon" src={Agendamento} />{" "} Novo Agendamento
          </button>
        </PositionBtn>
        <PositionFilter>
          <button
            onClick={() => {
              ViewModal();
            }}
          >
            <img alt="icon" src={Vector} />
            Filtro
          </button>
        </PositionFilter></>
        )}
      </Container>

      {open === true ? <ModalSchedule open={open} funcao={ViewModal} /> : ""}
    </>
  );
}
