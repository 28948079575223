import React, { useEffect, useState, useRef } from "react";
import Headerhome from "./components/header_home";
import Footerhome from "./components/footer_home";
import { PositionFooter, Container, InfinitySroll } from "./style";
import LeftMenu from "./components/left_menu/index";
import Schedule from "./components/schedule/index";
import NewSchedule from "./components/new_schedule";
import UsageHistory from "./components/usage_history/index";
import { StateGlobal } from "../../Utility/Context/RegisterContext";
import RequestExam from "./components/request_exam";
import TechnicalSupport from "./components/technical_support";
import Profile from "./components/profile";
import AutomaticRecharge from "./components/automatic_recharge";
import NotAuthorized from "./components/not_authorized"
export default function Home() {
  const { components } = StateGlobal();
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [scrolls, setScrolls] = useState({
    scrollTop: null,
    clientHeight: null,
    scrollHeight: null,
  });
  const { dataClient} = StateGlobal();
  const listInnerRef = useRef();

  function ViewValue() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  const onScroll = () => {
    let { scrollTop, clientHeight, scrollHeight } = listInnerRef.current;
    if (listInnerRef.current) {
      setScrolls({
        scrollTop: scrollTop,
        clientHeight: clientHeight,
        scrollHeight: scrollHeight,
      });
    }
  };

  return (
    <>
      {open === true ? (
        <LeftMenu open={ViewValue} />
      ) : (
        <>
          <Container>
            <div>
              <Headerhome setLoad={setLoad} load={load} open={ViewValue} position={components} />
            </div>
            <InfinitySroll onScroll={() => onScroll()} ref={listInnerRef}>
              {components === 0 ? (
                <UsageHistory
                  scrollTop={scrolls.scrollTop}
                  clientHeight={scrolls.clientHeight}
                  scrollHeight={scrolls.scrollHeight}
                  components={components}
                />
              ) : components === 1 ? (
                <Schedule
                  scrollTop={scrolls.scrollTop}
                  clientHeight={scrolls.clientHeight}
                  scrollHeight={scrolls.scrollHeight}
                  components={components}
                />
              ) : components === 1.1 ? (
                <NewSchedule />
              ) : components === 2 ? (
                <RequestExam />
              ) : dataClient.block === "sem_bloqueio" && components === 3 ? (
                <AutomaticRecharge />
              ) : dataClient.block !== "sem_bloqueio" && components === 3 ? (
                <NotAuthorized/>
              ) :
               components === 4 ? (
                <Profile />
              ) : components === 5 ? (
                <TechnicalSupport />
              ) : (
                ""
              )}
            </InfinitySroll>
            <PositionFooter>
              <Footerhome setLoad={setLoad} load={load} />
            </PositionFooter>
          </Container>
        </>
      )}
    </>
  );
}
