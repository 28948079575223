import styled from "styled-components";

export const Container = styled.div`
  background-color: #fafafa;
  height: 100%;
`;

export const PositionFooter = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const InfinitySroll = styled.div`
  padding-bottom: 70px;
  overflow-y: auto;
  flex: 1;
  overflow-x: hidden;
  max-height: 81%;
  z-index: 999;
  scroll-behavior: smooth;
  


  @media (min-height: 740px) {
    max-height: 84%
  }

`;
