import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SecondButton from "../SecondButton";
import { X } from "react-feather";
import OutlinedButtonBlue from "../OutlinedButtonBlue";
import SuccessIcon from "../../assets/SucessIcon.png";
import { Typography } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  paddingLeft: "30px",
};

export default function ModalSuccesDeleteDependent(props) {
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <img src={SuccessIcon} alt="SuccessIcon" width={60} />
            <h3 style={{ color: "#5E5873", marginBottom: "10px", fontSize: '20px', marginTop: '10px' }}>
              Solicitação efetuada com sucesso.
            </h3>
          </div>
          <p
            style={{
              fontSize: "15px",
              color: "#5e5873",
              lineHeight: "17px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Esse processo pode demorar até 72 horas úteis.
          </p>
          <SecondButton text="OK" funcao={props.CloseModal} />
        </Box>
      </Modal>
    </div>
  );
}
