import React, { useContext, useEffect, useState } from "react";
import logo from "../../../assets/LogoCadastro.png";

import PrimaryButton from "../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import Telefone from "../../../components/Inputs/telephone";
import CustomAlert from "../../../components/Alerts/CustomAlert";

import {
  Container,
  Step1,
  Step2,
  HeaderCadastro,
  Button,
  Icon,
  Alert,
  BoxText,
  UnderlineText,
  TextDisabled,
} from "./style";
import OtpInput from "react-otp-input";
import { RegisterContext } from "../../../Utility/Context/RegisterContext";
import conexao from "../../../Services/index";

export default function FirstAccessStep2() {
  const { firstAccessInfos } = useContext(RegisterContext);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [textAlert, setTextAlert] = useState("");

  const [auxTimer, setAuxTimer] = useState(false);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(2 * 60);
  const minutes = Math.floor(totalTimeInSeconds / 60);
  const seconds = totalTimeInSeconds % 60;

  const navigate = useNavigate();

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  async function ResendToken() {
    setAuxTimer(true);
    try {
      const { data } = await conexao.post(
        "api/user/first_access/token/create",
        {
          id: firstAccessInfos.user_id,
        }
      );
      setTextAlert(firstAccessInfos?.message);
      if (data.error === `false`) {
        setTextAlert(data.message);
        setAlertType("success");
        setShowAlertError(true);
      } else {
        setTextAlert(data.message);
        setAlertType("error");
        setShowAlertError(true);
      }
    } catch (err) {}
  }

  async function handleNext() {
    try {
      const { data } = await conexao.put("api/user/first_access/update", {
        password: password2,
      });

      setTextAlert(data.message);
      if (data.error === `false`) {
        setTextAlert(data?.message);
        setAlertType("success");
        setShowAlertError(true);
        setTimeout(() => {
          navigate("/");
        }, [1500]);
      } else {
        setTextAlert(data?.error);
        setAlertType("error");
        setShowAlertError(true);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  useEffect(() => {
    if (totalTimeInSeconds === 0) {
      setTotalTimeInSeconds(2 * 60);
      return;
    } else if (auxTimer === true) {
      setTimeout(() => {
        setTotalTimeInSeconds(totalTimeInSeconds - 1);
      }, 1000);
    }
  }, [totalTimeInSeconds, auxTimer]);

  return (
    <>
      <Container>
        <HeaderCadastro>
          <Icon>
            <ChevronLeft
              color="#FFF"
              onClick={() => {
                navigate("/primeiro-acesso/1");
              }}
            />
          </Icon>
          <img
            src={logo}
            alt="logo"
            width={120}
            height={80}
          />
          <p>Primeiro acesso</p>
          <div>
            <Step1 />
            <Step2 />
          </div>
        </HeaderCadastro>
        <BoxText>
          <p>Por favor cadastre abaixo uma senha de acesso de até 4 números:</p>
        </BoxText>

        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
          }}
        >
          <OtpInput
            isInputNum={true}
            numInputs={4}
            separator={" "}
            value={password1}
            onChange={(e) => {
              setPassword1(e);
            }}
            inputStyle={{
              width: "3rem",
              marginLeft: "0.25rem",
              marginRight: "0.25rem",
              height: "3rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "1px solid #ccc",
            }}
          />
        </div>

        <BoxText>
          <p>Por favor confirme a sua senha de acesso de 4 números:</p>
        </BoxText>

        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
          }}
        >
          <OtpInput
            isInputNum={true}
            numInputs={4}
            separator={" "}
            value={password2}
            onChange={(e) => {
              setPassword2(e);
            }}
            inputStyle={{
              width: "3rem",
              marginLeft: "0.25rem",
              marginRight: "0.25rem",
              height: "3rem",
              fontSize: "2rem",
              borderRadius: 4,
              border: "1px solid #ccc",
            }}
          />
        </div>

        {password1.length === 4 &&
        password2.length === 4 &&
        password1 !== password2 ? (
          <p style={{ textAlign: "center", color: `red`, paddingTop: `20px` }}>
            As senhas devem ser iguais
          </p>
        ) : (
          ""
        )}

        <Button>
          <PrimaryButton
            text="Próximo"
            funcao={handleNext}
            disabled={
              password1 === password2 && password1 !== "" ? false : true
            }
          />
        </Button>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={textAlert}
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
      </Container>
    </>
  );
}
