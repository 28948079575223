import React, { createContext, useState, useContext, useEffect } from "react";

export const RegisterContext = createContext({});

export default function CountProvider({ children }) {
  const [register, setRegister] = useState({
    name: "",
    telephone: "",
    email: "",
    cpf: "",
    password: "",
    dataNasc: "",
    howMeet: "",
    cep: "",
    address: "",
    neighborhood: "",
    number: "",
    complement: "",
    city: "",
    state: "",
  });

  const [dependentInfo, setDependentInfo] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    rg: "",
    cpf: "",
    birthday: "",
    how_meet: "",
    addresses: "",
    whatsapp: "",
    situation: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    address_id: "",
    zip_code: "",
    state: "",
    city: "",
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    type_address: "",
  });

  const [dataClient, setDataClient] = useState("");
  const [ModalExitIsTrue, setModalExistIsTrue] = useState(false);
  const [components, setComponents] = useState(0);
  const [dataHistoric, setDataHistoric] = useState([]);
  const [rowId, setRowId] = useState();
  const [infoHistoryById, setInfoHistoryById] = useState([]);
  const [infoScheduleById, setInfoScheduleById] = useState([]);
  const [auxMoney, setAuxMoney] = useState(false);
  const [order_id, setOrder_id] = useState("");
  const [checkout_id, setCheckout_id] = useState("");
  const [bodyFilterHistoric, setBodyFilterHistoric] = useState({
    start: null,
    end: null,
    page: 1,
    send: false,
  });
  const [bodyFilterScheduling, setBodyFilterScheduling] = useState({
    start: null,
    end: null,
    page: 1,
    send: false,
  });
  const [rechargeSteps, setRechargeSteps] = useState(1);
  const [valueRecharged, setValueRecharged] = useState("");
  const [public_key, setPublic_key] = useState("");
  const [info_card, setInfo_card] = useState({
    document_number: "",
    card_holder_name: "",
    card_number: "",
    card_cvv: "",
    card_expiration_date: "",
  });
  const [address_info, setAddress_info] = useState({
    zip_code: "",
    street: "",
    neighborhood: "",
    number: "",
    complement: "",
    city: "",
    state: "",
  });

  const [firstAccessInfos, setFirstAccessInfos] = useState();

  ///////////////// functions /////////////
  function verify() {
    setBodyFilterHistoric({
      start: null,
      end: null,
      page: 1,
      send: false,
    });
    setBodyFilterScheduling({
      start: null,
      end: null,
      page: 1,
      send: false,
    });
  }
  useEffect(() => {
    verify();
  }, [components]);

  return (
    <RegisterContext.Provider
      value={{
        register,
        setRegister,
        components,
        setComponents,
        bodyFilterHistoric,
        setBodyFilterHistoric,
        dataHistoric,
        setDataHistoric,
        ModalExitIsTrue,
        setModalExistIsTrue,
        rowId,
        setRowId,
        dependentInfo,
        setDependentInfo,
        infoHistoryById,
        setInfoHistoryById,
        infoScheduleById,
        setInfoScheduleById,
        dataClient,
        setDataClient,
        bodyFilterScheduling,
        setBodyFilterScheduling,
        addressInfo,
        setAddressInfo,
        auxMoney,
        setAuxMoney,
        rechargeSteps,
        setRechargeSteps,
        valueRecharged,
        setValueRecharged,
        info_card,
        setInfo_card,
        address_info,
        setAddress_info,
        order_id,
        setOrder_id,
        checkout_id,
        setCheckout_id,
        public_key,
        setPublic_key,
        firstAccessInfos,
        setFirstAccessInfos,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
}

export function StateGlobal() {
  const context = useContext(RegisterContext);
  const { dataClient, setDataClient } = context;
  const { components, setComponents } = context;
  const { bodyFilterHistoric, setBodyFilterHistoric } = context;
  const { dataHistoric, setDataHistoric } = context;
  const { ModalExitIsTrue, setModalExistIsTrue } = context;
  const { bodyFilterScheduling, setBodyFilterScheduling } = context;
  const { rowId, setRowId } = context;
  const { infoHistoryById, setInfoHistoryById } = context;
  const { infoScheduleById, setInfoScheduleById } = context;
  const { dependentInfo, setDependentInfo } = context;
  const { addressInfo, setAddressInfo } = context;
  const { auxMoney, setAuxMoney } = context;
  const { rechargeSteps, setRechargeSteps } = context;
  const { valueRecharged, setValueRecharged } = context;
  const { info_card, setInfo_card } = context;
  const { address_info, setAddress_info } = context;
  const { order_id, setOrder_id } = context;
  const { checkout_id, setCheckout_id } = context;
  const { public_key, setPublic_key } = context;
  const { firstAccessInfos, setFirstAccessInfos } = context;

  return {
    components,
    setComponents,
    bodyFilterHistoric,
    setBodyFilterHistoric,
    dataHistoric,
    setDataHistoric,
    ModalExitIsTrue,
    setModalExistIsTrue,
    rowId,
    setRowId,
    infoHistoryById,
    setInfoHistoryById,
    infoScheduleById,
    setInfoScheduleById,
    dependentInfo,
    setDependentInfo,
    dataClient,
    setDataClient,
    bodyFilterScheduling,
    setBodyFilterScheduling,
    addressInfo,
    setAddressInfo,
    auxMoney,
    setAuxMoney,
    rechargeSteps,
    setRechargeSteps,
    valueRecharged,
    setValueRecharged,
    info_card,
    setInfo_card,
    address_info,
    setAddress_info,
    order_id,
    setOrder_id,
    checkout_id,
    setCheckout_id,
    public_key,
    setPublic_key,
    firstAccessInfos,
    setFirstAccessInfos,
  };
}
