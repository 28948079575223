import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
`;

export const HeaderCadastro = styled.div`
  background-color: #08559f;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0px 0px 30px 30px;

  p {
    font-family: Montserrat, sans-serif;
    font-size: 17px;
    font-weight: 400px;
    color: #fff;
    margin-top: 20px;
  }

  div {
    display: flex;
    margin-top: 5px;
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
`;

export const Content = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    margin-bottom: 15px;
  }

  b {
    margin-bottom: 20px;
    font-size: 1.15rem;
  }

  p {
    font-size: 1.05rem;
    text-align: center;
    width: 75%;
    font-weight: 500;
  }
`;

export const ButtonDiv = styled.div`
  margin-top: 100px;
  width: 100%;
  padding: 0px 40px;
`;

export const Button = styled.button`
  width: 300px;
  height: 70px;
  border-radius: 5px;
  border: 3px solid #08559f;
  background-color: #fff;
  color: #08559f;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
`;
