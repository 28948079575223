import styled from "styled-components";

export const Container = styled.div`
  background-color: #fafafa;
  width: 100%;
  height: 100%;

  #sub-container {
    display: flex;
    align-items: flex-end;
  }

  #div-pen {
    background-color: #51c6d6;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    margin-left: -35px;
    margin-bottom: 0px;
  }
`;

export const Image = styled.div`
  width: 94px;
  height: 94px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
    border: solid 3px #08559f;
    object-fit: cover;
  }
`;

export const Form = styled.div`
  width: 100%;
  padding: 10px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 400px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const DivButton = styled.div`
  width: 100%;
  padding: 20px 10px 0px 10px;
`;

export const DivLoading = styled.div`
  display: flex;
  justify-content: center;
`;

export const Alert = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
