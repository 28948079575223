import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #00000030;
  border-radius: ${(props) =>
    `${props.props === 4 ? "0px 0px 0px 0px;" : "0px 0px 20px 20px;"}`};
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Icon = styled.div`
  width: 20%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerInfo = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
`;

export const Info = styled.div`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  p {
    font-size: 14px;
    color: #08559f;
    font-weight: 600;
  }

  #card {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
`;

export const AvatarLogo = styled.div`
  display: flex;
  align-items: center;
  height: 80px;

  img {
    width: 60px;
    height: 60px;
    margin: 10px;
    border: 3px solid #08559f;
    object-fit: cover;
    border-radius: 50%;
    /* padding: 10px; */
  }
`;

export const Saldo = styled.div`
  margin-top: -5px;
  padding-bottom: 15px;
`;

export const ProfileImageBlocked = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;

  img {
    border-radius: 50%;
    border: 5px solid #ea5455;
    width: 60px;
    height: 60px;
    margin: 10px 10px 0px 10px;
    object-fit: cover;
  }

  p {
    padding: 0px 5px;
    margin-top: -5px;
    font-size: 12px;
    background-color: #ea5455;
    color: #fff;
    border-radius: 10px;
    border: 5px solid #ea5455;
  }
`;
