import React from "react";
import { Container, Content } from "./styles";
import LogoLoading from "../../assets/LogoLoading.png";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingPage() {
  return (
    <Container>
      <Content>
        <img src={LogoLoading} alt="logo-loading" width={220} height={145} />
        <b>Solicitação Registrada com sucesso.</b>
        <div>
          <CircularProgress
            style={{
              color: "#FFF",
              width: "80px",
              height: "80px"
            }}
          />
        </div>
        <p>Estamos lhe redirecionando para uma de nossas atendentes...</p>
      </Content>
    </Container>
  );
}
