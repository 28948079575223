import React, { useState } from "react";
import Logo from "../../../assets/Logo.png";
import { TextField } from "@mui/material";
import {
  Container,
  HeaderLogin,
  FormLogin,
  ButtonSend,
  ButtonBack,
  Alert,
} from "./style";
import PrimaryButton from "../../../components/PrimaryButton";
import OutlinedButton from "../../../components/OutlinedButton";
import api from "../../../Services";
import CustomAlert from "../../../components/Alerts/CustomAlert";
export default function ForgotPassword() {
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [email, setEmail] = useState("");

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  async function HandleReset() {
    try {
      const res = await api.post("/api/user/password_reset", {
        email,
      });
      if (res.data.error) {
        setAlertType("error");
        setShowAlertError(true);
      }
      if (res.data.message) {
        setAlertType("success");
        setShowAlertOk(true);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  return (
    <Container>
      <HeaderLogin>
        <div>
          <img alt="logo" src={Logo} />
        </div>
      </HeaderLogin>

      <FormLogin>
        <p>
          Por favor informe seu e-mail de cadastro para enviarmos uma nova senha
          de acesso:
        </p>
        <TextField
          style={{ backgroundColor: "#fff", borderRadius: "5px" }}
          placeholder="Digite seu e-mail de cadastro"
          size="small"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ButtonSend>
          <PrimaryButton
            text="Enviar uma nova senha de acesso"
            funcao={HandleReset}
          />
        </ButtonSend>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={
              alertType === "success"
                ? "Nova senha enviada com sucesso!"
                : "Erro ao trocar senha!"
            }
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
        {showAlertOk || showAlertError === true ? (
          ""
        ) : (
          <ButtonBack>
            <OutlinedButton text="Voltar" route="/" />
          </ButtonBack>
        )}
      </FormLogin>
    </Container>
  );
}
