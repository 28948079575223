import styled from "styled-components";
import ImagePonto from "../../../../../assets/home/imgPonto.png";

export const Container = styled.div`
  display: flex;
  width: 94%;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px 0px #00000045;
  border-radius: 10px;
  margin: 10px 0px;
  margin-left: 3%;
  height: 115px;
`;

export const Date = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: solid #00000014 2px;
  margin: 5px;

  #Day {
    font-weight: 600;
    font-size: 25px;
  }

  #datePlus {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #de2e2e;
  }

  p {
    font-size: 14px;
  }
`;

export const ContainerInfor = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  color: #00000090;
  width: 250px;
  flex-direction: column;
  justify-content: center;

  #TitleStatus {
    display: flex;
    justify-content: space-between;
    height: 30px;

    span {
      padding: 3px 5px;
      background-color: ${(props) =>
      `${props.status === 'aguardando' ? "#989898" :
      props.status === 'finalizado' ? "#2EDE6A" :
      props.status === 'cancelado' ? "#de2e2e" :
      props.status === 'remarcado' ?  "#EDDE5A" : 
      props.status === 'agendado' ?  "#00CFE8" : ''}`};
      border-radius: 10px;
      width: 90px;
      color: #fff;
      font-size: 10px;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      margin-right: 8px;
      text-transform: capitalize
    }
  }

  #TypeCard {
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }

  #ValueCardPlus {
    font-weight: 400;
    font-size: 25px;
    color: ${(props) =>
      `${props.props === "Nova Recarga efetuada" ? "#2EDE6A" : "#de2e2e"}`};
  }

  #forma {
    width: 200px;
  }
`;
