import React, { useState } from "react";
import HeaderBarCreate from "../CreateNewDependent/Components/HeaderBarCreate/index";
import { Container } from "./style";
import CreateDependents from "../CreateNewDependent/Components/CreateDependents1";
import CreateDependents2 from "../CreateNewDependent/Components/CreateDependents2/index";
import CreateDependents3 from "../CreateNewDependent/Components/CreateDependents3/index";
export default function CreateNewDependent() {
  const [step, setStep] = useState(1);
  return (
    <>
      <Container>
        <HeaderBarCreate />
        {step === 1 ? (
          <CreateDependents setStep={setStep} />
        ) : step === 2 ? (
          <CreateDependents2 setStep={setStep} />
        ) : step === 3 ? (
          <CreateDependents3 setStep={setStep} />
        ) : (
          ""
        )}
      </Container>
    </>
  );
}
