import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#47C756",
  borderRadius: "5px",
  width: "100%",
  height: "45px",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "initial",
  "&:hover": {
    //  backgroundColor: "#47C756",
  },

  // "@media (max-width:660px)": {
  //   width: "160px",
  // },
}));

export default function RequestButton({ text, route, funcao, icon, disabled }) {
  const navigate = useNavigate();

  return (
    <StyledButton
      disabled={disabled}
      variant="contained"
      onClick={() => {
        if (funcao) {
          funcao();
        } else {
          navigate(`${route}`);
        }
      }}
    >
      {icon ? (
        <img alt="icon" src={icon} style={{ paddingRight: "10px" }} />
      ) : (
        ""
      )}
      {text}
    </StyledButton>
  );
}
