import React, { useState } from "react";
import HeaderBarEdit from "../../components/EditDependent/Components/HeaderBarEdit/index";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container } from "./style";
import EditDependent1 from "../../components/EditDependent/Components/EditDependents1/index";
import EditDependent2 from "../../components/EditDependent/Components/EditDependents2/index";
import EditDependent3 from "../../components/EditDependent/Components/EditDependents3/index";

export default function EditDependent() {
  const [step, setStep] = useState(3);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container>
        <HeaderBarEdit />
        <Box sx={{ width: "100%", typography: "body1", backgroundColor: '#FFF' }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#FFF",
                boxShadow: "0px 0px 2px 0px #00000030",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Dados Pessoais"
                  value="1"
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "#08559f",
                    fontFamily: "Montserrat",
                    textTransform: "initial",
                  }}
                />
                <Tab
                  label="Dados de Cobrança"
                  value="2"
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "#08559f",
                    fontFamily: "Montserrat",
                    textTransform: "initial",
                  }}
                />
                <Tab
                  label="Senha"
                  value="3"
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "#08559f",
                    fontFamily: "Montserrat",
                    textTransform: "initial",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: "10px 20px" }}>
              <EditDependent1 />
            </TabPanel>
            <TabPanel value="2" style={{ padding: "10px 20px" }}>
              <EditDependent2 />
            </TabPanel>
            <TabPanel value="3" style={{ padding: "20px 20px" }}>
              <EditDependent3 />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </>
  );
}
