import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PersonalData from "../personal_data";
import BillingData from "../billing_data";
import Dependents from "../dependents";

export default function Profile() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#FFF",
            boxShadow: "2px 2px 8px 0px #00000030",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Dados Pessoais"
              value="1"
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: "#08559f",
                fontFamily: "Montserrat",
                textTransform: "initial",
              }}
            />
            <Tab
              label="Dados de Cobrança"
              value="2"
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: "#08559f",
                fontFamily: "Montserrat",
                textTransform: "initial",
              }}
            />
            <Tab
              label="Dependentes"
              value="3"
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                color: "#08559f",
                fontFamily: "Montserrat",
                textTransform: "initial",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ padding: "10px 20px" }}>
          <PersonalData />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "10px 20px" }}>
          <BillingData />
        </TabPanel>
        <TabPanel value="3" style={{ padding: "20px 20px" }}>
          <Dependents />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
