import React, { useContext, useState } from "react";
import logo from "../../../assets/LogoCadastro.png";

import PrimaryButton from "../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import ValidatorCpf from "../../../components/Inputs/cpf";
import MenuItem from "@mui/material/MenuItem";
import { RegisterContext } from "../../../Utility/Context/RegisterContext";
import CustomAlert from "../../../components/Alerts/CustomAlert";

import {
  Container,
  Step1,
  Step2,
  Step3,
  Step4,
  HeaderCadastro,
  Form,
  Button,
  Icon,
  Alert,
} from "./style";

export default function Cadastro2() {
  const navigate = useNavigate();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const { register, setRegister } = useContext(RegisterContext);

  function handleNextPage() {
    if (
      register.cpf !== "" &&
      register.dataNasc !== "" &&
      register.howMeet !== ""
    ) {
      navigate("/cadastro/3");
    } else {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function dateMask(value) {
    value = value?.replace(/\D/g, "");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");

    return value;
  }

  const options = [
    {
      value: "empresarial",
      label: "Seu trabalho",
    },
    {
      value: "facebook",
      label: "Mídias Sociais",
    },
    {
      value: "google",
      label: "Google",
    },
    {
      value: "fachada_doutor_saude",
      label: "Outdoor",
    },
    {
      value: "indicacao",
      label: "Indicação",
    },
    {
      value: "indicacao_premiada",
      label: "Indicação Premiada",
    },
    {
      value: "outro_cartao_doutor_saude",
      label: "Outro Cartão",
    },
    {
      value: "panfleto",
      label: "Panfleto",
    },
    {
      value: "pessoalmente",
      label: "Pessoalmente",
    },
    {
      value: "reativacao",
      label: "Reativação do Plano",
    },
    {
      value: "site",
      label: "Nosso Site",
    },
    {
      value: "telefone",
      label: "Por telefone",
    },
    {
      value: "venda_lista",
      label: "Lista Telefônica",
    },
    {
      value: "clinicas_reunidas",
      label: "Clínicas Parceiras",
    },
    {
      value: "centro_medico",
      label: "Centro Médico Sorocaba",
    },
  ];

  const newCpf = register.cpf?.replace(/[^0-9s]/g, "");

  return (
    <>
      <Container>
        <HeaderCadastro>
          <Icon>
            <ChevronLeft
              color="#FFF"
              onClick={() => {
                navigate("/cadastro/1");
              }}
            />
          </Icon>
          <img src={logo} alt="logo" width={120} height={80} />
          <p>Cadastro</p>
          <div>
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
          </div>
        </HeaderCadastro>
        <div>
          <Form>
            <label id="cpf">CPF</label>
            <TextField
              id="cpf"
              value={ValidatorCpf(register.cpf)}
              maxLength={14}
              size="small"
              placeholder="Por favor digite seu nome completo"
              onChange={(e) =>
                setRegister({ ...register, cpf: e.target.value })
              }
            />
            <label id="nascimento">Data de Nascimento</label>
            <TextField
              size="small"
              id="nascimento"
              value={
                register.dataNasc
                  ? dateMask(register.dataNasc)
                  : register.dataNasc
              }
              placeholder="Digite sua Data de Nascimento"
              onChange={(e) =>
                setRegister({ ...register, dataNasc: e.target.value })
              }
            />
            <label id="meet">Como ficou sabendo do Dr. Saúde</label>
            <TextField
              id="meet"
              size="small"
              placeholder="Selecione uma opção"
              value={register.howMeet}
              select
              onChange={(e) =>
                setRegister({ ...register, howMeet: e.target.value })
              }
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Form>
        </div>
        <Button>
          <PrimaryButton text="Próximo" funcao={handleNextPage} />
        </Button>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={alertType === "error" && "Preencha todos os campos!"}
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
      </Container>
    </>
  );
}
