import React, { useState } from "react";
import {
  Container,
  ImageAndTitle,
  Title,
  Form,
  Button,
  InfoTelephone,
  Contactwhatsapp,
  ContactTelephone,
  Alert,
} from "./style";
import IconSupport from "../../../../assets/home/IconSupport.png";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../../components/PrimaryButton";
import whatsapp from "../../../../assets/components/whatsapp_green.png";
import Telefone from "../../../../assets/components/telefone_black.png";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import api from "../../../../Services/index";
export default function TechnicalSupport() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);

  async function handleSubmit() {
    try {
      if (description !== "" && title !== "") {
        const response = await api.post("/api/user/support", {
          title,
          description,
        });
        if (response.data) {
          setAlertType("success");
          setShowAlertOk(true);
          setTimeout(() => {
            window.location.href = "/home";
          }, 1500);
        }
      } else {
        setAlertType("error");
        setShowAlertError(true);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <Container>
      <ImageAndTitle>
        <img src={IconSupport} width={25} alt="IconSupport" />
        <Title>Suporte Técnico</Title>
      </ImageAndTitle>
      <Form>
        <label id="subject">Assunto</label>
        <TextField
          id="subject"
          size="small"
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <label id="message">Mensagem</label>
        <TextField
          multiline
          rows={7}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Form>
      <Button>
        <PrimaryButton text="Solicitar suporte agora" funcao={handleSubmit} />
      </Button>
      <InfoTelephone>
        <Contactwhatsapp>
          <img src={whatsapp} alt="whatsapp" />
          <a href="https://wa.me/5515997970092" target="_blank">
            Contato whatsapp
          </a>
        </Contactwhatsapp>
        <ContactTelephone>
          <img src={Telefone} alt="Telefone" />
          <p>Tel: (15) 99797 0092</p>
        </ContactTelephone>
      </InfoTelephone>
      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Suporte solicitado com sucesso!"
              : "Erro ao solicitar o suporte técnico!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
    </Container>
  );
}
