import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleHistory = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;

  img {
    width: 28px;
    padding-left: 3px;
  }

  h1 {
    margin: 0px;
    margin-left: 10px;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #5e5873;
  }
`;

export const PositionFilter = styled.div`
  padding: 10px;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 0px 3px 5px 0px #00000045;
  }

  #remove{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #08559F;
    border: none;
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
  }
`;
