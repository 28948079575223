import React, { useEffect, useState } from "react";
import TimeLine from "./TimeLine/index";
import UsageHistoryTitle from "./UsageHistoryTitle/index";
import conexao from "../../../../Services/index";
import { CircularProgress } from "@mui/material";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import ModalDetailsHistoric from "../../../../components/ModalDetailsHistoric";

export default function UsegeHistory(props) {
  const { setComponents } = StateGlobal();

  const {
    bodyFilterHistoric,
    setBodyFilterHistoric,
    dataHistoric,
    setDataHistoric,
    rowId,
  } = StateGlobal();
  const [progress, setProgress] = useState(true);
  const [finalProgress, setFinalProgress] = useState(false);
  const [endData, setEndData] = useState(0);
  const [open, setOpen] = useState(false);
  const [valueRowDatails, setValueRowDatails] = useState();

  const attPhoto = localStorage.getItem("attPhoto");

  async function getDetails() {
    try {
      const response = await conexao.post(
        "api/user/schedule/historic/detailed",
        { schedule_id: rowId }
      );
      setValueRowDatails(response.data);
    } catch (err) {}
  }

  useEffect(() => {
    getDetails();
  }, [rowId]);

  function viewModal() {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }

  function checkPage() {
    if (props.scrollTop + props.clientHeight === props.scrollHeight) {
      setBodyFilterHistoric({
        page: bodyFilterHistoric.page + 1,
        start: bodyFilterHistoric.start,
        end: bodyFilterHistoric.end,
        send: bodyFilterHistoric.send,
      });
      if (endData === 1) {
        setFinalProgress(false);
      } else {
        setFinalProgress(true);
      }
    }
  }

  async function getHistoric() {
    try {
      const response = await conexao.post("api/user/historic", {
        start: bodyFilterHistoric.start,
        end: bodyFilterHistoric.end,
        page: bodyFilterHistoric.page,
      });
      if (response.data.length !== 0) {
        setDataHistoric((oldState) => [...oldState, ...response.data]);
        setFinalProgress(false);
      }
      if (response.data.length === 0) {
        setEndData(1);
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (bodyFilterHistoric.send !== true && bodyFilterHistoric.start === null) {
      getHistoric();
    }
  }, [bodyFilterHistoric.page]);

  useEffect(() => {
    checkPage();
  }, [props.scrollTop]);

  useEffect(() => {
    if (bodyFilterHistoric.send === true) {
      setProgress(true);
      setDataHistoric([]);
      setTimeout(() => {
        setProgress(false);
      }, [2000]);
      getHistoric();
    } else if (bodyFilterHistoric.send === false) {
      setProgress(true);
      setDataHistoric([]);
      setTimeout(() => {
        setProgress(false);
      }, [2000]);
    }
  }, [bodyFilterHistoric.send]);

  useEffect(() => {
    if (attPhoto) {
      setComponents(4);
      localStorage.removeItem("attPhoto");
    }
  }, []);
  return (
    <>
      {progress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "450px",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {open === true ? (
            <ModalDetailsHistoric
              open={open}
              funcao={viewModal}
              info={valueRowDatails}
            />
          ) : (
            ""
          )}
          <UsageHistoryTitle />
          {dataHistoric.map((item, index) => {
            return (
              <div key={String(index)}>
                <TimeLine info={item} open={viewModal} rowId={index.id} />
              </div>
            );
          })}

          {finalProgress ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "100px",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
