import styled from "styled-components";

export const Container = styled.div`
  background-color: #08559f;
  width: 100%;
  height: 100%;
`;

export const HeaderLogin = styled.div`
  div {
    background-color: #fff;
    border-radius: 0px 0px 30px 30px;
    display: flex;
    justify-content: center;
    box-shadow: 2px 2px 8px 0px #00000030;
  }

  img {
    width: 250px;
    margin: 20px 0px 10px 0px 
  }
`;

export const FormLogin = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    /* margin-top: 25px; */
    margin-bottom: 5px;
  }
`;

export const EsqueceuSenha = styled.p`
  color: #51c6d6 !important ;
  cursor: pointer;
`;

export const LinhaComTexto = styled.div`
  width: 95%;
  height: 20px;
  border-bottom: 1px solid #fff;
  text-align: center;
`;

export const LinhaTexto = styled.span`
  font-size: 16px;
  color: #fff !important;
  background-color: #08559f;
  padding: 10px 10px;
  margin-top: -20px;
`;

export const FormCadastro = styled.div`
  width: 100%;
  /* margin-top: 30px; */
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #fff;
    padding-bottom: 5px;
  }
`;

export const Alert = styled.div`
  width: "100%";
  display: "flex";
  justify-content: "center";
`;
