import axios from "axios";
import { getPublicKey, getCheckoutSession } from "./auth";

// const public_key = getPublicKey();
const checkout_session = getCheckoutSession();
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Authorization: localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token")
      : "",
    // 'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    // 'Content-Type': 'application/json;charset=UTF-8',
    // 'Access-Control-Allow-Origin': '*'
  },
});
export const api_payhow = axios.create({
  baseURL: process.env.REACT_APP_PAYHOW_URL,

  headers: {
    Authorization: localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token")
      : "",
    Accept: "application/json",
    ContentType: "application/json",
    "public-key": localStorage.getItem("public_key"),
    "checkout-session": checkout_session,
    // 'Access-Control-Allow-Origin': '*'
  },
});

api.interceptors.response.use((response) => {
  if (response.message) {
    throw response;
  } else {
    return response;
  }
});

export default api;
