import React, { useEffect, useState } from "react";
import {
  Container,
  ImageAndTitle,
  Title,
  Form,
  DateAndCheck,
  Fields,
  DivFields,
  Button,
  InfoTelephone,
  Alert,
} from "./style";
import icon_schedule from "../../../../assets/home/icon_schedule.png";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import RequestButton from "../../../../components/RequestButton";
import whatsapp from "../../../../assets/components/whatsapp.png";
import Telefone from "../../../../assets/components/telefone_black.png";
import api from "../../../../Services";
import MenuItem from "@mui/material/MenuItem";
import conexao from "../../../../Services/index";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import ModalAlert from "../../../../components/ModalAlert";

export default function NewSchedule() {
  const [open, setOpen] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [doctorsList, setDoctorList] = useState([]);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [specialties, setSpecialties] = useState("");
  const [doctors, setDoctors] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOk, setIsOk] = useState(true);
  const { components, setComponents, dataClient } = StateGlobal();
  const state = {
    date: { value: "", error: false },
    checkbox: { value: false, error: false },
    especialidade: { value: "", error: false },
    medico: { value: "", error: false },
    cidade: { value: "", error: false },
    estado: { value: "", error: false },
  };
  const [form, setForm] = useState(state);
  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function handleIsOpen() {
    setOpen(!open);
    setComponents(5);
  }

  useEffect(() => {
    setOpen(true);
  }, []);

  function checkFormSend() {
    if (form.checkbox.value === true) {
      if (
        form.checkbox.value !== false &&
        form.especialidade.value !== "" &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        setIsOk(false);
      } else {
        setIsOk(true);
      }
    } else {
      if (
        form.date.value !== "" &&
        form.especialidade.value !== "" &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        setIsOk(false);
      } else {
        setIsOk(true);
      }
    }
  }
  useEffect(() => {
    checkFormSend();
  }, [form]);

  function validate(value) {
    if (value === "" || value === null || value === undefined) {
      return true;
    } else {
      return false;
    }
  }

  function pegadados(e) {
    const { name, value, checked } = e;
    if (name === "date") {
      setForm({
        ...form,
        [name]: { value: value, error: validate(value) },
      });
    }
    if (name === "checkbox") {
      setForm({ ...form, [name]: { value: checked, error: validate(value) } });
    }
    if (name === "especialidade") {
      setForm({
        ...form,
        [name]: { value: value, error: validate(value) },
      });
    }
    if (name === "medico") {
      setForm({ ...form, [name]: { value: value, error: validate(value) } });
    }
    if (name === "cidade") {
      setForm({
        ...form,
        [name]: { value: value, error: validate(value) },
      });
    }
    if (name === "estado") {
      setForm({ ...form, [name]: { value: value, error: validate(value) } });
    }
  }

  const states = [
    { label: "AC", value: "AC" },
    { label: "AL", value: "AL" },
    { label: "AP", value: "AP" },
    { label: "AM", value: "AM" },
    { label: "BA", value: "BA" },
    { label: "CE", value: "CE" },
    { label: "DF", value: "DF" },
    { label: "ES", value: "ES" },
    { label: "GO", value: "GO" },
    { label: "MA", value: "MA" },
    { label: "MT", value: "MT" },
    { label: "MS", value: "MS" },
    { label: "MG", value: "MG" },
    { label: "PA", value: "PA" },
    { label: "PB", value: "PB" },
    { label: "PR", value: "PR" },
    { label: "PE", value: "PE" },
    { label: "PI", value: "PI" },
    { label: "RJ", value: "RJ" },
    { label: "RN", value: "RN" },
    { label: "RS", value: "RS" },
    { label: "RO", value: "RO" },
    { label: "RR", value: "RR" },
    { label: "SC", value: "SC" },
    { label: "SP", value: "SP" },
    { label: "SE", value: "SE" },
    { label: "TO", value: "TO" },
  ];

  async function handleGetSelects() {
    try {
      const responseSpecialist = await api.get(
        "/api/user/schedule/specialties_list"
      );
      setSpecialtiesList(responseSpecialist.data);
    } catch (err) {}
  }

  async function handleGetDoctors() {
    const responseDoctorsList = await api.post(
      "/api/user/schedule/doctors_list",
      {
        specialty_id: form.especialidade.value,
      }
    );
    setDoctorList(responseDoctorsList.data);
    setIsDisabled(false);
  }
  useEffect(() => {
    if (form.especialidade.value !== "") {
      handleGetDoctors();
    }
  }, [form.especialidade.value]);

  useEffect(() => {
    handleGetSelects();
  }, []);

  function send() {
    if (form.checkbox.value === true) {
      if (
        form.checkbox.value !== false &&
        form.especialidade.value !== "" &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        conexao
          .post("api/user/schedule/request", {
            planned_date: "",
            specialty_id: form.especialidade.value,
            doctor_id: form.medico.value,
            city: form.cidade.value,
            state: form.estado.value,
          })
          .then((res) => {
            setAlertType("success");
            setShowAlertOk(true);
            setTimeout(() => {
              window.location.href =
                "https://api.whatsapp.com/send?phone=5515997970092";
              // setComponents(1);
            }, 1000);
          })
          .catch((error) => {
            setAlertType("error");
            setShowAlertError(true);
          });
      }
      // podemos fazer uma verificação pelo erro se necessário
      // else if (form.user.value === "" || form.password.value === "") {
      //   setForm({
      //     ...form,
      //     user: { error: validate(form.user.value) },
      //     password: { error: validate(form.password.value) },
      //   });
      // }
    } else {
      if (
        form.date.value !== "" &&
        form.especialidade.value !== "" &&
        form.cidade.value !== "" &&
        form.estado.value !== ""
      ) {
        conexao
          .post("api/user/schedule/request", {
            planned_date: form.date.value,
            specialty_id: form.especialidade.value,
            doctor_id: form.medico.value,
            city: form.cidade.value,
            state: form.estado.value,
          })
          .then((res) => {
            setAlertType("success");
            setShowAlertOk(true);
            setTimeout(() => {
              setComponents(1);
            }, 2000);
          })
          .catch((error) => {
            setAlertType("error");
            setShowAlertError(true);
          });
      }
    }
  }

  return (
    <Container>
      <ImageAndTitle>
        <img
          src={icon_schedule}
          width={25}
          alt="icon_schedule"
        />
        <Title>Novo Agendamento</Title>
      </ImageAndTitle>
      <label
        id="date"
        style={{ paddingLeft: "20px" }}
      >
        Data Planejada
      </label>
      <DateAndCheck>
        <TextField
          disabled={form.checkbox.value === true ? true : false}
          name="date"
          id="date"
          size="small"
          fullWidth
          type="date"
          style={{ maxWidth: "180px" }}
          onChange={(e) => {
            pegadados(e.target);
          }}
        />
        <Checkbox
          style={{ color: "#51C6D6" }}
          name="checkbox"
          onChange={(e) => {
            pegadados(e.target);
          }}
        />
        <p>O mais breve possível</p>
      </DateAndCheck>
      <Form>
        <label id="especialist">Especialidade</label>
        <TextField
          name="especialidade"
          id="especialist"
          size="small"
          type="text"
          select
          onChange={(e) => {
            pegadados(e.target);
          }}
        >
          {specialtiesList.map((specialtiesList) => (
            <MenuItem
              key={specialtiesList.id}
              value={specialtiesList.id}
            >
              {specialtiesList.name}
            </MenuItem>
          ))}
        </TextField>
        <label id="doctor">Médico ou Profissional (opcional)</label>
        <TextField
          name="medico"
          id="doctor"
          size="small"
          type="text"
          select
          disabled={isDisabled}
          onChange={(e) => {
            pegadados(e.target);
          }}
        >
          {doctorsList !== "" &&
            doctorsList.map((doctorsList) => (
              <MenuItem
                key={doctorsList.id}
                value={doctorsList.id}
              >
                {doctorsList.name}
              </MenuItem>
            ))}
        </TextField>
      </Form>
      <DivFields>
        <Fields>
          <label id="city">Cidade</label>
          <TextField
            id="city"
            size="small"
            type="text"
            name="cidade"
            onChange={(e) => {
              pegadados(e.target);
            }}
          />
        </Fields>
        <Fields>
          <label
            id="state"
            style={{ marginLeft: "15px" }}
          >
            Estado
          </label>

          <TextField
            name="estado"
            style={{ marginLeft: "15px" }}
            id="state"
            size="small"
            type="text"
            select
            fullWidth
            onChange={(e) => {
              pegadados(e.target);
            }}
          >
            {states.map((states) => (
              <MenuItem
                key={states.value}
                value={states.value}
              >
                {states.value}
              </MenuItem>
            ))}
          </TextField>
        </Fields>
      </DivFields>
      <Button>
        <RequestButton
          text="Solicitar Agendamento"
          icon={whatsapp}
          disabled={isOk}
          funcao={send}
        />
      </Button>
      <InfoTelephone>
        <p>ou ligue:</p>
        <div>
          <img
            src={Telefone}
            alt="Telefone"
          />
          <span>Tel: (15) 99797 0092</span>
        </div>
      </InfoTelephone>

      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Agendamento solicitado"
              : "Erro ao Agendar!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
      {dataClient?.block_status === true ? (
        <ModalAlert
          status={open}
          funcao={handleIsOpen}
        />
      ) : (
        ""
      )}
    </Container>
  );
}
