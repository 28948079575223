import React, { useState } from "react";
import IconMenu from "../../../../../assets/home/iconMenu.png";
import Vector from "../../../../../assets/home/Vector.png";
import { Container, TitleHistory, PositionFilter } from "./style";
import ModalFilter from "../../../../../components/ModalFilterHistoric/index";
import { StateGlobal } from "../../../../../Utility/Context/RegisterContext";
import { X } from "react-feather";

export default function UsageHistory(props) {
  const [open, setOpen] = useState(false);
  const [viewClearBtn, setViewClearBtn] = useState(false);
  const { bodyFilterHistoric, setBodyFilterHistoric } = StateGlobal();

  function ViewModal() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  function clearFilter() {
    if (
      bodyFilterHistoric.start !== null ||
      bodyFilterHistoric.start !== "" ||
      bodyFilterHistoric.end !== null ||
      bodyFilterHistoric.end !== ""
    ) {
      setBodyFilterHistoric({
        start: null,
        end: null,
        page: 1,
        send: false,
      });
    }
    setViewClearBtn(false);
  }

  return (
    <>
      <Container>
        <TitleHistory>
          <img alt="icon" src={IconMenu} />
          <h1>Histórico de uso</h1>
        </TitleHistory>
        <PositionFilter>
          {bodyFilterHistoric.send === true ? (
            <button
              id="remove"
              onClick={() => {
                clearFilter();
              }}
            >
              <X
                color="#fff"
                size={22}
              />
              remover filtro
            </button>
          ) : (
            <button
              onClick={() => {
                ViewModal();
              }}
            >
              <img alt="icon" src={Vector} />
              Filtro
            </button>
          )}
        </PositionFilter>
      </Container>

      {open === true ? <ModalFilter open={open} funcao={ViewModal} /> : ""}
    </>
  );
}
