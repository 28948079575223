import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Button, DivImage } from "./style";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../PrimaryButton";
import Logo from "../../../../assets/Logo.png";
import { RegisterContext } from "../../../../Utility/Context/RegisterContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Telefone from "../../../Inputs/telephone";
export default function CreateDependents({ setStep }) {
  const { register, setRegister } = useContext(RegisterContext);
  const [isDisabled, setIsDisabled] = useState(true);

  function handleNextStep() {
    setStep(2);
  }

  function handleIsDisabled() {
    register.name !== "" &&
    register.telephone !== "" &&
    register.email !== "" &&
    register.cpf !== "" &&
    register.dataNasc !== "" &&
    register.howMeet !== ""
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }

  useEffect(() => {
    handleIsDisabled();
  }, [register]);

  function maskCpf(v) {
    v = v?.replace(/\D/g, "");

    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    return v;
  }

  const options = [
    {
      value: "empresarial",
      label: "Seu trabalho",
    },
    {
      value: "facebook",
      label: "Mídias Sociais",
    },
    {
      value: "google",
      label: "Google",
    },
    {
      value: "fachada_doutor_saude",
      label: "Outdoor",
    },
    {
      value: "indicacao",
      label: "Indicação",
    },
    {
      value: "indicacao_premiada",
      label: "Indicação Premiada",
    },
    {
      value: "outro_cartao_doutor_saude",
      label: "Outro Cartão",
    },
    {
      value: "panfleto",
      label: "Panfleto",
    },
    {
      value: "pessoalmente",
      label: "Pessoalmente",
    },
    {
      value: "reativacao",
      label: "Reativação do Plano",
    },
    {
      value: "site",
      label: "Nosso Site",
    },
    {
      value: "telefone",
      label: "Por telefone",
    },
    {
      value: "venda_lista",
      label: "Lista Telefônica",
    },
    {
      value: "clinicas_reunidas",
      label: "Clínicas Parceiras",
    },
    {
      value: "centro_medico",
      label: "Centro Médico Sorocaba",
    },
  ];

  function dateMask(value) {
    value = value?.replace(/\D/g, "");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");

    return value;
  }

  return (
    <Container>
      <DivImage>
        <img src={Logo} alt="logo" width={180} />
      </DivImage>
      <Form>
        <label id="name">Nome Completo</label>
        <TextField
          id="name"
          type="text"
          size="small"
          placeholder="Por favor digite seu nome completo"
          value={register.name}
          onChange={(e) => setRegister({ ...register, name: e.target.value })}
        />
        <label id="telefone">Telefone de Contato</label>
        <TextField
          id="telefone"
          type="text"
          size="small"
          placeholder="Digite seu telefone"
          inputProps={{ maxLength: 15 }}
          value={Telefone(register.telephone)}
          onChange={(e) =>
            setRegister({ ...register, telephone: e.target.value })
          }
        />

        <label id="email">E-mail</label>
        <TextField
          id="email"
          type="text"
          size="small"
          placeholder="Digite seu melhor e-mail"
          value={register.email}
          onChange={(e) => setRegister({ ...register, email: e.target.value })}
        />
        <label id="cpf">CPF</label>
        <TextField
          id="cpf"
          type="text"
          size="small"
          placeholder="Digite seu CPF"
          inputProps={{ maxLength: 14 }}
          value={maskCpf(register.cpf)}
          onChange={(e) => setRegister({ ...register, cpf: e.target.value })}
        />

        <label id="nascimento">Data de Nascimento</label>
        <TextField
          size="small"
          id="nascimento"
          placeholder="Digite sua Data de Nascimento"
          value={dateMask(register.dataNasc)}
          onChange={(e) =>
            setRegister({ ...register, dataNasc: e.target.value })
          }
        />
        <label id="meet">Como ficou sabendo do Dr. Saúde</label>
        <Select
          id="meet"
          size="small"
          style={{ marginBottom: "25px" }}
          value={register.howMeet}
          onChange={(e) => {
            setRegister({ ...register, howMeet: e.target.value });
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Form>
      <Button>
        <PrimaryButton
          text="Próximo"
          funcao={handleNextStep}
          disabled={isDisabled}
        />
      </Button>
    </Container>
  );
}
