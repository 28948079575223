import React, { useContext, useState, useEffect } from "react";

import OtpInput from "react-otp-input";
import SecondButton from "../../../SecondButton";
import OutlinedButtonBlue from "../../../OutlinedButtonBlue";

import Logo from "../../../../assets/Logo.png";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import { useNavigate } from "react-router";

import { Container, Form, Button, ButtonTwo, DivImage, Alert } from "./style";
import { RegisterContext } from "../../../../Utility/Context/RegisterContext";
import api from "../../../../Services/index";
import CustomAlert from "../../../Alerts/CustomAlert";
import * as moment from "moment";

export default function CreateDependents3({ setStep }) {
  const { setComponents } = StateGlobal();
  const navigate = useNavigate();

  const { register, setRegister } = useContext(RegisterContext);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  function handleBackStep() {
    setStep(2);
  }

  async function HandleSubmit() {
    if (
      register.name !== "" &&
      register.email !== "" &&
      register.cpf !== "" &&
      register.dataNasc !== "" &&
      register.howMeet !== "" &&
      register.cep !== "" &&
      register.address !== "" &&
      register.telephone !== "" &&
      register.neighborhood !== "" &&
      register.number !== "" &&
      register.complement !== "" &&
      register.city !== "" &&
      register.state !== "" &&
      register.password !== ""
    ) {
      try {
        var data = moment(register.dataNasc, "DD/MM/YYYY");

        const response = await api.post("/api/user/dependent/register", {
          name: register.name,
          phone: register.telephone?.replace(/[^0-9s]/g, ""),
          email: register.email,
          cpf: register.cpf?.replace(/[^0-9s]/g, ""),
          birthday: data.format("YYYY-MM-DD"),
          zip_code: register.cep?.replace(/[^0-9s]/g, ""),
          state: register.state,
          city: register.city,
          street: register.address,
          number: register.number,
          neighborhood: register.neighborhood,
          complement: register.complement,
          password: register.password,
          how_meet: register.howMeet,
          type_address: "principal",
          card_type: "pre",
          whatsapp: "",
          rg: "",
          company_id: "",
          photo: "",
          id_dep: "",
          genre: "",
          schooling: "",
          holder_income: "",
          working_department: "",
          marital_status: "",
          spouse_name: "",
          spouse_birthday: "",
          father_name: "",
          mother_name: "",
        });
        if (response.data) {
          setAlertType("success");
          setShowAlertOk(true);
          setTimeout(() => {
            setComponents(4);
            navigate("/home");
            setRegister({
              name: "",
              phone: "",
              email: "",
              cpf: "",
              birthday: "",
              zip_code: "",
              state: "",
              city: "",
              street: "",
              number: "",
              neighborhood: "",
              complement: "",
              password: "",
              how_meet: "",
            });
          }, 1500);
        }
      } catch (err) {
        setAlertType("error");
        setShowAlertError(true);
      }
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function handleIsDisabled() {
    register.password !== "" && register.password?.length === 4
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }

  useEffect(() => {
    handleIsDisabled();
  }, [register]);

  return (
    <>
      <Container>
        <DivImage>
          <img src={Logo} alt="logo" width={180} />
        </DivImage>
        <Form>
          <p>Por favor cadastre abaixo uma senha de acesso de 4 números:</p>
          <div>
            <OtpInput
              onChange={(e) => {
                setRegister({ ...register, password: e });
              }}
              isInputNum={true}
              value={register.password}
              numInputs={4}
              separator={" "}
              inputStyle={{
                width: "3rem",
                marginLeft: "0.25rem",
                marginRight: "0.25rem",
                height: "3rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid #ccc",
              }}
            />
          </div>
        </Form>
        <Button>
          <SecondButton
            text="Concluir Cadastro"
            funcao={HandleSubmit}
            disabled={isDisabled}
          />
        </Button>
        <ButtonTwo>
          <OutlinedButtonBlue text="Voltar" funcao={handleBackStep} />
          <Alert>
            <CustomAlert
              type={alertType}
              alertText={
                alertType === "success"
                  ? "Dependente cadastrado com sucesso!"
                  : "Erro ao cadastrar novo dependente!"
              }
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />
          </Alert>
        </ButtonTwo>
      </Container>
    </>
  );
}
