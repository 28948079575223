import React, { useState } from "react";
import { Header, Alert } from "./style";
import { X } from "react-feather";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import api from "../../../../Services/index";
import CustomAlert from "../../../../components/Alerts/CustomAlert";

export default function HeaderBarEdit() {
  const { setModalExistIsTrue } = StateGlobal();

  return (
    <>
      <Header>
        <X
          color="#08559F"
          onClick={() => {
            setModalExistIsTrue(true);
          }}
        />
        <h3>Editar Dependente</h3>

        <p></p>
      </Header>
    </>
  );
}
