import React from "react";
import logo from "../../../assets/LogoCadastro.png";
import SucessIcon from "../../../assets/SucessIcon.png";
import SecondButton from "../../../components/SecondButton";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Content,
  HeaderCadastro,
  Icon,
  ButtonDiv,
  Button,
} from "./style";

export default function Cadastro5() {
  const navigate = useNavigate();

  function backToHome() {
    navigate("/");
  }

  return (
    <>
      <Container>
        <HeaderCadastro>
          <Icon>
            <ChevronLeft
              color="#FFF"
              onClick={() => {
                navigate("/cadastro/2");
              }}
            />
          </Icon>
          <img src={logo} alt="logo" width={120} height={80} />
        </HeaderCadastro>
        <Content>
          <div>
            <img src={SucessIcon} alt="SucessIcon" width={120} height={120} />
            <b>Cadastro efetuado com sucesso</b>
            <p>
              Em breve nossa equipe fará contato com você através do seu e-mail
              de cadastro ou telefone de contato. Obrigado!!!
            </p>
            <ButtonDiv>
              <SecondButton text="Entendido!" funcao={backToHome} />
              {/* <Button>Utilizar meu cartão</Button> */}
            </ButtonDiv>
          </div>
        </Content>
      </Container>
    </>
  );
}
