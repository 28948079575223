import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

export const ImageAndTitle = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px 20px 20px;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #5e5873;
`;

export const ContainerProgres = styled.div`
  width: 80%;
  margin: 10px;
`;

export const LineProgres = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 13px;
  border-bottom: 3px solid #dcdcdc;
`;

export const Circulo = styled.div`
  width: 25px;
  height: 25px;
  background-color: #08559f;
  border-radius: 50%;
`;

export const CirculoLine = styled.div`
  width: 25px;
  height: 25px;
  background-color: #fafafa;
  border-radius: 50%;
  border: 2px solid #08559f;
`;

export const ContainerLabelProgres = styled.div`
  width: 90%;
  margin: 5px;
`;

export const ContainerLabelSpace = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContainerLabel = styled.div`
  width: 70px;
  height: 40px;
  text-align: center;
`;

export const LabelText = styled.span`
  font-size: 12px;
  text-align: center;
  font-weight: 600;
`;

export const Button = styled.div`
  width: 100%;
  padding: 20px 0px 0px 0px;
`;

export const ButtonBack = styled.div`
  width: 100%;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
`;

export const TextButtonBack = styled.span`
  color: #08559f;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 600;
`;

export const DivCard = styled.div`
  width: 100%;
  padding: 15px 30px 10px 30px;
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
`;
export const TextRecharge = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #000;
`;

export const Form = styled.div`
  width: 100%;
  padding: 20px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Fields = styled.div`
  width: 100%;
  max-width: 350px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
