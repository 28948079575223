import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #51c6d6;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;

  b {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    width: 60%;
  }

  div {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  p {
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    color: #fafafa;
    width: 65%;
  }
`;
