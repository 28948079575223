import React, { useState, useEffect } from "react";
import Modal from "./modal";
import SecondButton from "../../../../components/SecondButton";
import IconAddUser from "../../../../assets/home/IconAddUser.png";
import {
  Container,
  Button,
  Modals,
  DivLoading,
  ContainerInformation,
} from "./style";
import api from "../../../../Services";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";

export default function Dependents() {
  const [infoDependents, setInfoDependents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableDependents, setAvailableDependents] = useState("");

  async function handleGetDependents() {
    try {
      setLoading(true);
      const response = await api.get("/api/user/dependent");
      if (response.data.length !== 0) {
        setInfoDependents(response.data);
      }
      setLoading(false);
    } catch (err) {}
  }

  async function handleGetInfo() {
    try {
      setLoading(true);
      const { data } = await api.get("/api/user");
      setAvailableDependents(data?.total_cadastros_disponiveis);
    } catch (err) {}
  }

  useEffect(() => {
    handleGetInfo();
    handleGetDependents();
  }, []);

  return (
    <>
      {loading ? (
        <DivLoading>
          <CircularProgress style={{ marginTop: "30px" }} />
        </DivLoading>
      ) : (
        <Container>
          <Button>
            <SecondButton
              text="Novo Dependente"
              icon={IconAddUser}
              route={"/create/dependent"}
              disabled={availableDependents <= 0}
            />
          </Button>
          {availableDependents <= 0 && (
            <ContainerInformation>
              <Alert severity="error">
                {infoDependents.error
                  ? infoDependents.error
                  : `
                Não é possível cadastrar novos dependentes pois você chegou ao
                limite de cadastros do seu plano ou não possui um plano Dr.
                Saúde vinculado ao seu cadastro!`}
              </Alert>
            </ContainerInformation>
          )}
          {infoDependents.error
            ? ""
            : infoDependents?.map((item, index) => {
                return (
                  <Modals key={String(index)}>
                    <Modal
                      id={item.id}
                      name={item.name}
                      email={item.email}
                      phone={item.phone}
                      rg={item.rg}
                      cpf={item.cpf}
                      birthday={item.birthday}
                      addresses={item.addresses}
                      how_meet={item.how_meet}
                      whatsapp={item.whatsapp}
                      situation={item.situation}
                    />
                  </Modals>
                );
              })}
        </Container>
      )}
    </>
  );
}
