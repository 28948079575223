import axios from "axios";
import { cpf } from "cpf-cnpj-validator";

export function mcc(v) {
  v = v?.replace(/\D/g, ""); // Permite apenas dígitos
  v = v?.replace(/(\d{4})/g, "$1 "); // Coloca um ponto a cada 4 caracteres
  v = v?.replace(/\.$/, ""); // Remove o ponto se estiver sobrando
  v = v.substring(0, 19); // Limita o tamanho
  return v;
}

export function validasenha(e) {
  if (e.length < 4) {
    return "A senha deve ter no mínimo 4 caracteres";
  }
  if (e === "") {
    return "Esse campo precisa ser preenchido";
  }
  return false;
}

// export function teste2(e) {
//   if (e.length > 5) {
//     return true
//   }
//   return false
// }

export function telefonefixo(e) {
  let value = e;
  value = value?.replace(/\D/g, "");
  value = value?.replace(/(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
  return value;
}

export function dataehora(e) {
  if (e !== null) {
    const arr = e.split(" ");

    let dataSplit = arr[0].split("-");

    const dataformatada = `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;

    let horaSplit = arr[1].split(":");

    const horaformatada = `${horaSplit[0]}:${horaSplit[1]}`;

    return `${dataformatada} às ${horaformatada}`;
  }
  return null;
}

export function telefones(e) {
  let value = e;
  value = value?.replace(/\D/g, "");
  value = value
    ?.replace(/(\d{2})(\d)/, "($1) $2")
    ?.replace(/(\d{4})(\d)/, "$1-$2")
    ?.replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    ?.replace(/(-\d{4})\d+?$/, "$1");
  return value;
}

export function dataRegex(e) {
  let value = e;
  value = value?.replace(/\D/g, "");
  value = value
    ?.replace(/(\d{2})(\d)/, "$1/$2")
    ?.replace(/(\d{2})(\d)/, "$1/$2")
    ?.replace(/(\d{2})(\d{4})/, "$1/$2")
    ?.replace(/(\d{4})\d+?$/, "$1");
  return value;
}

export function data(e) {
  const arr = e.split(" ");
  let dataSplit = arr[0].split("-");
  const dataformatada = `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;

  return dataformatada;
}

export function hora(e) {
  const arr = e.split(" ");
  let dataSplit = arr[1].split(":");

  const horaformatada = `${dataSplit[0]}:${dataSplit[1]}`;
  return horaformatada;
}

export function current(e) {
  let value = e;

  value += "";
  value = value?.replace(/[\D]+/g, "");
  value += "";
  value = value?.replace(/([0-9]{2})$/g, ".$1");

  if (value.length > 6)
    value = value?.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1.$2");

  return value;
}

export function currencyinput(e) {
  let value = e.toString();

  value = value?.replace(/\D/g, "");
  value = value?.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value?.replace(/(?=(\d{3})+(\D))\B/g, ".");

  return value;
}

export function celular(e) {
  let value = e;
  value = value?.replace(/\D/g, "");
  value = value?.replace(/(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  return value.substring(0, 15);
}

// export function cpfValidate(e) {
//   let value = e
//   value = value?.replace(/\D/g, '')
//   value = value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
//   return value
// }

export function rg(e) {
  let value = e;
  value = value?.replace(/\D/g, "");
  value = value?.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");

  return value;
}

// export function cnpjValidate(e) {
//   let value = e
//   value = value?.replace(/\D/g, '')
//   value = value?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
//   return value
// }

export function ValidaCep(cep) {
  // eslint-disable-next-line no-useless-escape
  const exp = /\d{2}\.\d{3}\-\d{3}/;
  if (!exp.test(cep.value)) {
    return false;
  }
  return true;
}

export function apicep(cep) {
  axios
    .get(`https://viacep.com.br/ws/${cep?.replace(/\D/g, "")}/json/`)
    .then((res) => {})
    .catch((error) => {});
}

export function validaCpfCnpj(val) {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf?.replace(/\./g, "");
    cpf = cpf?.replace("-", "");
    cpf = cpf.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj?.replace(/\./g, "");
    cnpj = cnpj?.replace("-", "");
    cnpj = cnpj?.replace("/", "");
    cnpj = cnpj.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function maskCpfCnpj(v) {
  v = v?.replace(/\D/g, "");

  if (v.length <= 11) {
    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d)/, "$1.$2");
    v = v?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v?.replace(/^(\d{2})(\d)/, "$1.$2");
    v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    v = v?.replace(/\.(\d{3})(\d)/, ".$1/$2");
    v = v?.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return v;
}

export function validateCpfCnpj(value) {
  // eslint-disable-next-line no-useless-escape
  return cpf.isValid(value);
}

export function validateEmail(email) {
  if (email === "") {
    return "Email inválido";
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email).toLowerCase().toLowerCase())) {
    return "Email inválido";
  }

  return false;
}

export function validatePasswordLogin(password) {
  if (password.length < 6) {
    return false;
  }
  return true;
}
