import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
`;

export const HeaderBar = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 15px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #00000030;

  h3 {
    color: #5e5873;
  }

  p {
    text-decoration: underline;
    color: #08559f;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const DivImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Form = styled.div`
  width: 100%;
  padding: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #cep {
    margin-top: 0px;
  }

  label {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 400px;
    color: #333;
    margin-top: 25px;
    margin-bottom: 5px;
  }
`;

export const Button = styled.div`
  width: 100%;
`;

export const FieldsAndButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const Fields = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
`;

export const Alert = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;


