import React, { useState, useEffect } from "react";
import { RiEyeCloseLine } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { BiRefresh } from "react-icons/bi";
import { Container, SubContainer, Saldo, Value, IconEye } from "./style";
import { StateGlobal } from "../../../../../Utility/Context/RegisterContext";

export default function CurrentBalance() {
  const [open, setOpen] = useState(false);
  const { dataClient, auxMoney, setAuxMoney } = StateGlobal();

  function ViewValue() {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }

  function refreshMoney() {
    setAuxMoney(!auxMoney);
  }

  return (
    <Container>
      <SubContainer>
        <Saldo>
          {dataClient.tipo_cartao === "pre" ? (
            <>
              <p>Seu</p>
              <p id="atual">saldo atual:</p>
            </>
          ) : (
            <>
              <p>Seu</p>
              <p id="atual">limite atual:</p>
            </>
          )}
        </Saldo>
        <Value>
          <p>R$</p>
          {open === true ? (
            dataClient.tipo_cartao === "pre" ? (
              <p id="secret">
                {new Intl.NumberFormat("pt-BR", {
                  minimumFractionDigits: 2,
                }).format(dataClient.saldo ? dataClient.saldo : "")}
              </p>
            ) : (
              <p id="secret">{dataClient.limite ? dataClient.limite : ""}</p>
            )
          ) : (
            <p id="secret"> *****,**</p>
          )}
        </Value>
      </SubContainer>

      <IconEye>
        <BiRefresh
          fill="#08559F"
          style={{ height: "1.4em", width: "1.4em", marginRight: "10px" }}
          onClick={() => {
            document.location.reload(true);
            // refreshMoney();
          }}
        />
        {open === true ? (
          <FaEye
            fill="#08559F"
            style={{ height: "1.4em", width: "1.4em" }}
            onClick={() => {
              ViewValue();
            }}
          />
        ) : (
          <RiEyeCloseLine
            fill="#08559F"
            style={{ height: "1.4em", width: "1.4em" }}
            onClick={() => {
              ViewValue();
            }}
          />
        )}
      </IconEye>
    </Container>
  );
}
