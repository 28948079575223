import React, { useEffect, useState } from "react";
import {
  Container,
  Content,
  ImageAndTitle,
  Title,
  Circulo,
  CirculoLine,
  LineProgres,
  ContainerProgres,
  ContainerLabelProgres,
  ContainerLabelSpace,
  ContainerLabel,
  LabelText,
  Button,
  Form,
  ButtonPrice,
  ContainerBtnsPrice,
} from "./style";
import { StateGlobal } from "../../../../../../Utility/Context/RegisterContext";
import CardRechargeIcon from "../../../../../../assets/home/CardRechargeIcon.png";
import PrimaryButton from "../../../../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import conexao from "../../../../../../Services/index";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function RechargeValue() {
  const { setRechargeSteps, valueRecharged, setValueRecharged, setOrder_id } =
    StateGlobal();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [isOk, setIsOk] = useState(true);

  async function rechargeValue() {
    if (Number(valueRecharged?.replace(/[^0-9s]/g, "")) < 500) {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: "O valor da recarga deve ser no mínimo R$5,00.",
        showConfirmButton: true,
      });
      return;
    }
    setLoading(true);
    try {
      const response = await conexao.post("api/user/card/recharge", {
        origem: "cartao",
        valor: valueRecharged?.replace(/[^0-9s]/g, ""),
      });
      setOrder_id(response.data.id);
      setRechargeSteps(2);
      setLoading(false);
    } catch (err) {}
  }

  useEffect(() => {
    if (valueRecharged !== "") {
      setIsOk(false);
    } else {
      setIsOk(true);
    }
  }, [valueRecharged]);

  function plus50() {
    adicionarValor(50);
  }

  function plus100() {
    adicionarValor(100);
  }

  function plus200() {
    adicionarValor(200);
  }

  function adicionarValor(valorAdicional) {
    // Primeiro, remover a formatação atual de moeda para poder somar
    let valorAtual = parseInt(valueRecharged.replace(/[\D]+/g, "") || 0);

    // Adicionar o valorAdicional ao valorAtual
    let novoValor = valorAtual + valorAdicional * 100; // Multiplica por 100 para lidar com centavos

    // Formatar o novo valor e atualizá-lo
    formatarMoedaPlus({ value: novoValor.toString() });
  }

  function formatarMoedaPlus(e) {
    let { value } = e;
    value = value + "";
    let valor = parseInt(value.replace(/[\D]+/g, "")) || 0;

    valor = valor.toString();
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValueRecharged(valor);
  }

  function formatarMoeda(e) {
    const { value } = e;
    let valor = value;
    valor = valor + "";
    valor = parseInt(valor?.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor?.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor?.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    setValueRecharged(valor);

    if (valor === "NaN") {
      setValueRecharged("");
    }
  }

  return (
    <Container>
      <Content>
        <ImageAndTitle>
          <img src={CardRechargeIcon} width={25} alt="icon_schedule" />
          <Title>Recarregar seu Cartão</Title>
        </ImageAndTitle>

        <ContainerProgres>
          <LineProgres>
            <Circulo />
            <CirculoLine />
            <CirculoLine />
          </LineProgres>
        </ContainerProgres>

        <ContainerLabelProgres>
          <ContainerLabelSpace>
            <ContainerLabel>
              <LabelText>Valor de recarga</LabelText>
            </ContainerLabel>
            <ContainerLabel>
              <LabelText>Dados pessoais</LabelText>
            </ContainerLabel>
            <ContainerLabel>
              <LabelText>Dados de pagamento</LabelText>
            </ContainerLabel>
          </ContainerLabelSpace>
        </ContainerLabelProgres>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "450px",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Form>
              <label> Valor da Recarga </label>
              <TextField
                inputProps={{ maxLength: 10 }}
                type="tel"
                id="name"
                size="small"
                placeholder="R$ 0,00"
                value={valueRecharged}
                onChange={(e) => formatarMoeda(e.target)}
              />

              <ContainerBtnsPrice>
                <ButtonPrice
                  onClick={() => {
                    plus50();
                  }}
                >
                  50,00
                </ButtonPrice>
                <ButtonPrice
                  onClick={() => {
                    plus100();
                  }}
                >
                  100,00
                </ButtonPrice>
                <ButtonPrice
                  onClick={() => {
                    plus200();
                  }}
                >
                  200,00
                </ButtonPrice>
              </ContainerBtnsPrice>
            </Form>
            <Button>
              <PrimaryButton
                text="Próximo"
                funcao={rechargeValue}
                disabled={isOk}
              />
            </Button>
          </>
        )}
        {/* <Button>
          <TextBtn>Voltar</TextBtn>
        </Button> */}
      </Content>
    </Container>
  );
}
