import styled, { keyframes } from "styled-components";

const fadeInUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  background-color: #08559f;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px -4px 8px 0px #00000030;

  animation: ${fadeInUp} 0.5s ease-in-out;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    color: #fff;
    font-size: 10px;
    margin-top: 2px;
  }
`;
