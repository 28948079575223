import React from "react";
import { Header } from "./style";
import { X } from "react-feather";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import { useNavigate } from "react-router";
export default function HeaderBarCreate() {
  const { setComponents } = StateGlobal();
  const navigate = useNavigate();

  return (
    <Header>
      <X
        color="#08559F"
        onClick={() => {
          setComponents(4);
          navigate("/home");
        }}
      />
      <h3>Novo Dependente</h3>
      <p></p>
    </Header>
  );
}
