import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SecondButton from "../SecondButton";
import { X } from "react-feather";
import OutlinedButtonBlue from "../OutlinedButtonBlue";
import { StateGlobal } from "../../Utility/Context/RegisterContext";
import { useNavigate } from "react-router";
import CustomAlert from "../Alerts/CustomAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  paddingLeft: "30px",
};

export default function ModalExitDependent(props) {
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const { components, setComponents, ModalExitIsTrue, setModalExistIsTrue } =
    StateGlobal();

  const navigate = useNavigate();

  async function CloseAndSave() {
    props.HandleSubmit();
    if (props.alertType === "success") {
      navigate("/home");
      setComponents(4);
      setModalExistIsTrue(false);
    }
  }

  async function CloseAndNotSave() {
    setComponents(4);
    setModalExistIsTrue(false);
    navigate("/home");
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <X
              color="#08559F"
              style={{ position: "absolute", right: "10", top: "15" }}
              onClick={() => setModalExistIsTrue(false)}
            />

            <h3
              style={{
                color: "#5E5873",
                marginBottom: "10px",
                fontSize: "20px",
                marginTop: "25px",
              }}
            >
              Salvar alterações do dependente antes de sair?
            </h3>
          </div>
          <p
            style={{
              fontSize: "15px",
              color: "#5e5873",
              lineHeight: "17px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Existem informações desse dependente que ainda não foram salvas
          </p>
          <OutlinedButtonBlue text="Sair sem salvar" funcao={CloseAndNotSave} />
          <SecondButton text="Salvar e sair" funcao={CloseAndSave} />
        </Box>
      </Modal>
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: "10px",
        }}
      >
        <CustomAlert
          type={props.alertType}
          alertText={
            props.alertType === "success"
              ? "Informações atualizadas com sucesso!"
              : "Erro ao atualizar informações!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </div>
    </div>
  );
}
