import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Global.css";
import Login from "./pages/PageLogin/Login";
import ForgotPassword from "./pages/PageLogin/ForgotPassword";
import Cadastro from "./pages/PageRegister/Register";
import Cadastro2 from "./pages/PageRegister/Register2";
import Cadastro3 from "./pages/PageRegister/Register3";
import Cadastro4 from "./pages/PageRegister/Register4";
import Cadastro5 from "./pages/PageRegister/Register5";
import Home from "./pages/home/index";
import LeftMenu from "./pages/home/components/left_menu";
import LoadingPage from "./components/LoadingPage";
import ContextProvider from "./Utility/Context/RegisterContext";
import TechnicalSupport from "./pages/home/components/technical_support";
import CreateNewDependent from "./components/CreateNewDependent/index";
import EditDependent from "./components/EditDependent/index";
import "react-credit-cards/es/styles-compiled.css";
import FirstAccessStep1 from "./pages/FirstAccess/FirstAccessStep1/index";
import FirstAccessStep2 from "./pages/FirstAccess/FirstAccessStep2";

export default function App() {
  return (
    <ContextProvider>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={<Login />}
          />
          <Route
            exact
            path="/primeiro-acesso/1"
            element={<FirstAccessStep1 />}
          />
          <Route
            exact
            path="/primeiro-acesso/2"
            element={<FirstAccessStep2 />}
          />
          <Route
            exact
            path="/esqueceu-a-senha"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/cadastro/1"
            element={<Cadastro />}
          />
          <Route
            exact
            path="/cadastro/2"
            element={<Cadastro2 />}
          />
          <Route
            exact
            path="/cadastro/3"
            element={<Cadastro3 />}
          />
          <Route
            exact
            path="/cadastro/4"
            element={<Cadastro4 />}
          />
          <Route
            exact
            path="/cadastro/5"
            element={<Cadastro5 />}
          />
          <Route
            exact
            path="/home"
            element={<Home />}
          />
          <Route
            exact
            path="/leftmenu"
            element={<LeftMenu />}
          />
          <Route
            exact
            path="/loading"
            element={<LoadingPage />}
          />
          <Route
            exact
            path="/suporte"
            element={<TechnicalSupport />}
          />
          <Route
            exact
            path="/create/dependent"
            element={<CreateNewDependent />}
          />
          <Route
            exact
            path="/edit/dependent"
            element={<EditDependent />}
          />
        </Routes>
      </Router>
    </ContextProvider>
  );
}
