import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import SecondButton from "../SecondButton";
import { X } from "react-feather";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { StateGlobal } from "../../Utility/Context/RegisterContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  paddingLeft: "30px",
};

export default function ModalAlert(props) {
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.status}
        onClose={props.funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <X
            color="#08559F"
            style={{ position: "absolute", right: "15px", top: "15px" }}
            onClick={props.funcao}
          />
          <Alert
            severity="error"
            style={{
              marginBottom: "20px",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Seu cartão está bloqueado no momento, para maiores informações entre
            em contato com a nossa equipe.
          </Alert>

          <SecondButton
            text="Ok"
            funcao={props.funcao}
          />
        </Box>
      </Modal>
    </div>
  );
}
