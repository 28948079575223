import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
`;

export const HeaderCadastro = styled.div`
  background-color: #08559f;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0px 0px 30px 30px;
  box-shadow: 2px 2px 8px 0px #00000030;

  p {
    font-family: Montserrat, sans-serif;
    font-size: 17px;
    font-weight: 400px;
    color: #fff;
    margin-top: 20px;
  }

  div {
    display: flex;
    margin-top: 5px;
  }
`;
export const Step1 = styled.div`
  width: 40px;
  height: 5px;
  background-color: #fff;
  margin-left: 5px;
`;

export const Step2 = styled.div`
  width: 40px;
  height: 5px;
  background-color: #fff;
  margin-left: 5px;
`;

export const Step3 = styled.div`
  width: 40px;
  height: 5px;
  background-color: #fff;

  margin-left: 5px;
`;

export const Step4 = styled.div`
  width: 40px;
  height: 5px;
  background-color: #fff;
  margin-left: 5px;
`;

export const Form = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: Montserrat, sans-serif;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.div`
  width: 100%;
  padding: 150px 1.875rem 0px 1.875rem;
`;

export const Icon = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
`;

export const Alert = styled.div`
  width: "100%";
  display: "flex";
  justify-content: "center";
`;
