import React, { useState, useEffect } from "react";
import logo from "../../../../assets/Logo-LeftMenu.png";
import ProfileImg from "../../../../assets/ProfileImg.png";
import { ChevronRight, X } from "react-feather";
import { useNavigate } from "react-router-dom";
import api from "../../../../Services/index";
import { removeToken } from "../../../../Services/auth";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import logo_default from "../../../../assets/logo_default.png";
import { mcc } from "../../../../components/validates/Validate";
import {
  Container,
  Logo,
  CloseIcon,
  InfoProfile,
  DivHR,
  MenuOptions,
  DivMap,
  Alert,
  TextBlocked,
  ProfileImageBlocked,
} from "./styles";
import conexao from "../../../../Services/index";

export default function LeftMenu(props) {
  const navigate = useNavigate();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const {
    components,
    setComponents,
    setBodyFilterHistoric,
    bodyFilterHistoric,
    dataClient,
    setDataClient,
    setRechargeSteps,
    setValueRecharged,
    setAddress_info,
  } = StateGlobal();

  async function handleLogout() {
    try {
      const response = await api.post("/api/user/logout");
      if (response.data) {
        removeToken();
        setAlertType("success");
        setShowAlertOk(true);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function handleProfile() {
    setComponents(4);
    props.open();
    navigate("/home");
  }

  function handleSupport() {
    setComponents(5);
    props.open();
    navigate("/home");
  }

  function handleRecharge() {
    setComponents(3);
    setRechargeSteps(1);
    setValueRecharged("");
    setAddress_info("");
    props.open();
    navigate("/home");
  }

  function handleDependent() {
    setComponents(4);
    props.open();
    navigate("/home");
  }

  function handleHistoricUsage() {
    setComponents(0);
    setBodyFilterHistoric({
      page: 1,
      start: null,
      end: null,
      send: false,
    });
    props.open();
    navigate("/home");
  }

  function handleNewSchedule() {
    setComponents(1.1);
    props.open();
    navigate("/home");
  }

  function handleRequestExam() {
    setComponents(2);
    props.open();
    navigate("/home");
  }

  const options = [
    {
      name: "Minha Conta",
      path: "/",
    },
    {
      name: "Dependentes",
      path: "/",
    },
    {
      name: "Recarregar Cartão",
      path: "/",
    },
    {
      name: "Histórico de Uso",
      path: "/",
    },
    {
      name: "Solicitar Agendamento",
      path: "/",
    },
    {
      name: "Solicitar Exame",
      path: "/",
    },
    {
      name: "Suporte Técnico",
      path: "/",
    },
    {
      name: "Sair",
      path: "/",
    },
  ];

  return (
    <Container>
      <Logo>
        <img src={logo} alt="logo" width={85} />
      </Logo>
      <CloseIcon
        onClick={() => {
          props.open();
        }}
      >
        <X color="#08559f" />
      </CloseIcon>
      <InfoProfile>
        {dataClient.block === "sem_bloqueio" ? (
          <img
            src={dataClient.photo ? dataClient.photo : logo_default}
            alt="imgperfil"
            width={75}
            height={75}
          />
        ) : (
          <ProfileImageBlocked>
            <img
              src={dataClient.photo ? dataClient.photo : logo_default}
              alt="imgperfil"
              width={85}
              height={85}
            />
            <p>cartão bloqueado</p>
          </ProfileImageBlocked>
        )}
        <h4>{dataClient.name ? dataClient.name : "Carregando..."}</h4>

        <label>
          Cartão:{" "}
          {dataClient.card_number
            ? mcc(dataClient.card_number)
            : "Carregando..."}
        </label>
        {dataClient.block === "sem_bloqueio" ? (
          ""
        ) : (
          <TextBlocked>
            seu cartão está bloqueado no momento, para maiores informações entre
            em contato com a nossa equipe.
          </TextBlocked>
        )}
      </InfoProfile>
      <DivHR>
        <hr style={{ border: "1px solid #fafafa" }} />
      </DivHR>
      <MenuOptions>
        {options.map((item, index) =>
          item.name === "Recarregar Cartão" &&
          dataClient.tipo_cartao === `pos` ? (
            ""
          ) : (
            <DivMap
              key={String(index)}
              onClick={() => {
                item.name === "Minha Conta" && handleProfile();
                item.name === "Dependentes" && handleDependent();
                item.name === "Histórico de Uso" && handleHistoricUsage();
                item.name === "Solicitar Agendamento" && handleNewSchedule();
                item.name === "Solicitar Exame" && handleRequestExam();
                item.name === "Suporte Técnico" && handleSupport();
                item.name === "Sair" && handleLogout();
                item.name === "Recarregar Cartão" && handleRecharge();
              }}
            >
              <p> {item.name} </p>
              <td>
                <ChevronRight color="#CCCCCC" />{" "}
              </td>
            </DivMap>
          )
        )}
      </MenuOptions>
      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success" ? "Deslogando" : "Erro ao deslogar!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
    </Container>
  );
}
