import React, { useContext, useEffect, useState } from "react";

import OtpInput from "react-otp-input";
import PrimaryButton from "../../../PrimaryButton";
import OutlinedButtonBlue from "../../../../components/OutlinedButtonBlue";

import Logo from "../../../../assets/Logo.png";
import api from "../../../../Services/index";

import { Container, Form, Button, ButtonTwo, DivImage, Alert } from "./style";
import ModalExitDependent from "../../../../components/ModalExitDependent";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import CustomAlert from "../../../../components/Alerts/CustomAlert";

export default function EditDependents3({ setStep }) {
  const { dependentInfo, setDependentInfo, ModalExitIsTrue } = StateGlobal();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState("");

  const [password, setPassword] = useState("");

  async function HandleSubmit() {
    try {
      const response = await api.put(
        `/api/user/dependent/update/${dependentInfo.id}`,
        {
          name: dependentInfo.name,
          phone: dependentInfo.phone,
          birthday: dependentInfo.birthday,
          cpf: dependentInfo.cpf,
          whatsapp: "",
          rg: "",
          photo: "",
          genre: "",
          schooling: "",
          holder_income: "",
          working_department: "",
          marital_status: "",
          spouse_name: "",
          spouer_name: "",
          mothse_birthday: "",
          father_name: "",
          how_meet: dependentInfo.how_meet,
          password,
        }
      );
      if (response.data) {
        setAlertType("success");
        setShowAlertOk(true);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  function handleBackStep() {
    setStep(2);
  }

  function handleIsDisabled() {
    password !== "" && password.length === 4
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }

  useEffect(() => {
    handleIsDisabled();
  }, [password]);

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <>
      <Container>
        <DivImage>
          <img src={Logo} alt="logo" width={180} />
        </DivImage>
        <Form>
          <p>Por favor cadastre abaixo uma senha de acesso de 4 números:</p>
          <div>
            <OtpInput
              isInputNum={true}
              value={password}
              numInputs={4}
              separator={" "}
              inputStyle={{
                width: "3rem",
                marginLeft: "0.25rem",
                marginRight: "0.25rem",
                height: "3rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid #ccc",
              }}
              onChange={(e) => {
                setPassword(e);
              }}
            />
          </div>
          <Button>
            <PrimaryButton
              text="Atualizar Informações"
              disabled={isDisabled}
              funcao={HandleSubmit}
            />
          </Button>
          <ButtonTwo>
            <OutlinedButtonBlue text="Voltar" funcao={handleBackStep} />
          </ButtonTwo>
          {ModalExitIsTrue === true ? (
            <ModalExitDependent
              open={ModalExitIsTrue}
              HandleSubmit={HandleSubmit}
              alertType={alertType}
              showAlertOk={showAlertOk}
              showAlertError={showAlertError}
            />
          ) : (
            ""
          )}
        </Form>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={
              alertType === "success"
                ? "Informações atualizadas com sucesso!"
                : "Erro ao atualizar informações!"
            }
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
      </Container>
    </>
  );
}
