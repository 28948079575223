import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  DivImage,
  Fields,
  FieldsAndButton,
} from "./style";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../PrimaryButton";
import OutlinedButtonBlue from "../../../OutlinedButtonBlue";
import { RegisterContext } from "../../../../Utility/Context/RegisterContext";

import Logo from "../../../../assets/Logo.png";
import MenuItem from "@mui/material/MenuItem";

export default function CreateDependents2({ setStep }) {
  const { register, setRegister } = useContext(RegisterContext);
  const [isDisabled, setIsDisabled] = useState(true);

  function handleNextStep() {
    setStep(3);
  }

  function handleBackStep() {
    setStep(1);
  }

  const searchPostalCode = async (postalCode) => {
    fetch(`https://viacep.com.br/ws/${register.cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        setRegister({
          ...register,
          cep: data.cep,
          address: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      });
  };

  function handleIsDisabled() {
    register.cep !== "" &&
    register.address !== "" &&
    register.neighborhood !== "" &&
    register.city !== "" &&
    register.state !== "" &&
    register.number !== "" &&
    register.complement !== ""
      ? setIsDisabled(false)
      : setIsDisabled(true);
  }

  useEffect(() => {
    handleIsDisabled();
  }, [register]);

  useEffect(() => {
    register.cep.length === 8 && searchPostalCode();
  }, [register.cep]);

  return (
    <Container>
      <DivImage>
        <img src={Logo} alt="logo" width={180} />
      </DivImage>
      <Form>
        <label id="cep">CEP</label>

        <TextField
          type="tel"
          id="cep"
          size="small"
          placeholder="Digite seu CEP"
          value={register.cep}
          onChange={(e) => setRegister({ ...register, cep: e.target.value })}
        />
        <label id="endereco">Endereço</label>
        <TextField
          id="endereco"
          type="text"
          size="small"
          placeholder="Digite seu endereço completo"
          value={register.address}
          onChange={(e) =>
            setRegister({ ...register, address: e.target.value })
          }
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Fields>
            <label id="bairro">Bairro</label>
            <TextField
              id="bairro"
              type="text"
              size="small"
              placeholder="Digite seu nome bairro"
              value={register.neighborhood}
              onChange={(e) =>
                setRegister({ ...register, neighborhood: e.target.value })
              }
            />
          </Fields>
          <Fields style={{ marginLeft: "15px" }}>
            <label id="numero">Número</label>
            <TextField
              id="numero"
              type="number"
              size="small"
              placeholder="Digite o número"
              value={register.number}
              onChange={(e) =>
                setRegister({ ...register, number: e.target.value })
              }
            />
          </Fields>
        </div>
        <label id="complemento">Complemento</label>
        <TextField
          size="small"
          id="complemento"
          type="text"
          placeholder="Digite o complemento"
          value={register.complement}
          onChange={(e) =>
            setRegister({ ...register, complement: e.target.value })
          }
        />
        <FieldsAndButton>
          <Fields>
            <label id="cidade">Cidade</label>
            <TextField
              id="cidade"
              type="text"
              size="small"
              placeholder="Digite sua cidade"
              value={register.city}
              onChange={(e) =>
                setRegister({ ...register, city: e.target.value })
              }
            />
          </Fields>
          <Fields style={{ marginLeft: "15px" }}>
            <label id="estado">Estado</label>
            <TextField
              id="estado"
              type="text"
              size="small"
              placeholder="Digite seu estado"
              value={register.state}
              onChange={(e) =>
                setRegister({ ...register, state: e.target.value })
              }
            />
          </Fields>
        </FieldsAndButton>
      </Form>
      <Button>
        <PrimaryButton
          text="Próximo"
          funcao={handleNextStep}
          disabled={isDisabled}
        />
      </Button>
      <Button>
        <OutlinedButtonBlue text="Voltar" funcao={handleBackStep} />
      </Button>
    </Container>
  );
}
