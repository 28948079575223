import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImageAndTitle = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 10px 20px 20px 20px;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #5e5873;
`;

export const DateAndCheck = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 20px;

  p {
    font-size: 12px;
    font-family: Montserrat, sans-serif;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px;

  label {
    margin: 20px 0px 2px 0px;
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 2px;
  }
`;

export const DivFields = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 20px;
`;

export const Button = styled.div`
  width: 100%;
  padding: 10px 20px 20px 20px;
`;

export const DivDropzone = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px 0px 20px;
`;

export const InfoTelephone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0px 20px 20px 20px;

  p {
    font-size: 14px;
    color: #686868;
  }

  span {
    margin-top: 3px;
    font-size: 16px;
    color: #686868;
    font-weight: 600;
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 10px;
  }
`;

export const Alert = styled.div`
  width: "100%";
  display: "flex";
  justify-content: "center";
`;