import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SecondButton from "../SecondButton";
import { X } from "react-feather";
import OutlinedButtonBlue from "../OutlinedButtonBlue";
import { StateGlobal } from "../../Utility/Context/RegisterContext";
import api from "../../Services/index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  paddingLeft: "30px",
};

export default function ModalDeleteDependent(props) {
  const { dependentInfo, setDependentInfo } = StateGlobal();

  async function handleDeleteDependent() {
    try {
      const response = await api.delete(
        `/api/user/dependent/delete/${dependentInfo.id}`
      );

      if (response) {
        props.ViewSecondModal();
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <p
            style={{
              fontSize: "16px",
              color: "#5e5873",
              lineHeight: "17px",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Para excluir um dependente será necessário fazer contato com a nossa
            equipe solicitando esse cancelamento.
          </p>
          <OutlinedButtonBlue
            text="Solicitar exclusão"
            funcao={handleDeleteDependent}
          />
          <SecondButton text="Ok, entendi" funcao={props.CloseModal} />
        </Box>
      </Modal>
    </div>
  );
}
