import styled from "styled-components";
import ImagePonto from "../../../../../assets/home/imgPonto.png";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Date = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  #Day {
    font-weight: 600;
    font-size: 25px;
  }

  #month {
    text-transform: capitalize;
  }

  p {
    font-size: 14px;
  }
`;

export const ContainerLine = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerCircle = styled.div`
  background-image: url(${ImagePonto});
  height: 100px;
  width: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => `${
    props.props === "recharge"
      ? "#2EDE6A"
      : props.props === "transaction" && props.props2 === 'positivo'
      ? "#2EDE6A " 
      : props.props === "transaction" && props.props2 === "negativo"
      ? "#EA5455"
      : "#00CFE8"
  };
  }`};
  box-shadow: ${(props) =>
    `${
      props.props === "recharge"
        ? "#2EDE6A40 0px 0px 2px 4px;"
        : props.props === "transaction" && props.props2 === 'positivo'
        ?"#2EDE6A40 0px 0px 2px 4px;" 
        : props.props === "transaction" && props.props2 === "negativo"
        ? "#EA545540 0px 0px 2px 4px;"
        : "#00CFE840 0px 0px 2px 4px;"
    }`};
`;

export const ContainerInfor = styled.div`
  #TypeCard {
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }

  #ValueCardPlus {
    font-weight: 400;
    font-size: 25px;
    color: ${(props) =>
      `${props.props === "recharge"
      ? "#2EDE6A"
      : props.props === "transaction" && props.props2 === 'positivo'
      ? "#2EDE6A " 
      : props.props === "transaction" && props.props2 === "negativo"
      ? "#EA5455"
      : "#00CFE8"}`};
  }

  #datePlus {
    font-size: 16px;
    font-weight: 600;
    color: #686868;
  }

  #forma { 
    width: 200px;
  }
  font-weight: 500;
  font-size: 12px;
  color: #00000090;
  width: 250px;
`;
