import styled from "styled-components";

export const Container = styled.div`
  background-color: #fafafa;
  width: 100%;
  height: 100%;
`;

export const Form = styled.div`
  width: 100%;
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  label {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 400px;
    color: #333;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

export const Button = styled.div`
  width: 100%;
  padding: 20px 0px 0px 0px;
`;

export const Fields = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
`;

export const FieldsAndButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const Alert = styled.div`
  width: "100%";
  display: "flex";
  justify-content: "center";
`;

export const DivLoading = styled.div`
display: flex;
justify-content: center;
`
