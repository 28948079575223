function teste(e) {
  let value = e;

  value = value?.replace(/\D/g, "");
  value = value
    ?.replace(/(\d{2})(\d)/, "($1) $2")
    ?.replace(/(\d{4})(\d)/, "$1-$2")
    ?.replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    ?.replace(/(-\d{4})\d+?$/, "$1");
  return value;
}

function Telefone(e){
  e=e?.replace(/\D/g,""); //Remove tudo o que não é dígito
  e=e?.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  e=e?.replace(/(\d)(\d{4})$/,"$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return e;
}

export default Telefone;
