import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const SubContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: flex-start;
`;

export const Saldo = styled.div`
  padding: 0px 25px;

  p {
    font-weight: 600;
    font-size: 14px;
    color: #08559f;
  }

  #atual {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: flex-end;

  p {
    font-size: 25px;
    font-weight: 600;
    color: #51c6d6;
    padding-right: 5px;
  }

  #secret {
    font-weight: 500;
  }
`;

export const IconEye = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 30%;
`;
