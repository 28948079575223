import React, { useContext, useState } from "react";
import logo from "../../../assets/LogoCadastro.png";

import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import { RegisterContext } from "../../../Utility/Context/RegisterContext";
import OtpInput from "react-otp-input";
import SecondButton from "../../../components/SecondButton";
import api from "../../../Services/index";
import CustomAlert from "../../../components/Alerts/CustomAlert";
import * as moment from "moment";

import {
  Container,
  Step1,
  Step2,
  Step3,
  Step4,
  HeaderCadastro,
  Form,
  Button,
  Icon,
  Alert,
} from "./style";

export default function Cadastro4() {
  const navigate = useNavigate();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const { register, setRegister } = useContext(RegisterContext);
  const [textAlert, setTextAlert] = useState("");

  async function HandleSubmit() {
    try {
      var data = moment(register.dataNasc, "DD/MM/YYYY");

      if (
        register.name !== "" &&
        register.telephone !== "" &&
        register.email !== "" &&
        register.cpf !== "" &&
        register.dataNasc !== "" &&
        register.howMeet !== "" &&
        register.cep !== "" &&
        register.address !== "" &&
        register.neighborhood !== "" &&
        register.number !== "" &&
        register.complement !== "" &&
        register.city !== "" &&
        register.state !== "" &&
        register.password !== ""
      ) {
        const response = await api.post("/api/user/register", {
          name: register.name,
          phone: register.telephone?.replace(/[^0-9s]/g, ""),
          email: register.email,
          cpf: register.cpf?.replace(/[^0-9s]/g, ""),
          birthday: data.format("YYYY-MM-DD"),
          zip_code: register.cep?.replace(/[^0-9s]/g, ""),
          state: register.state,
          city: register.city,
          street: register.address,
          number: register.number,
          neighborhood: register.neighborhood,
          complement: register.complement,
          password: register.password,
          how_meet: register.howMeet,
          type_address: "principal",
          card_type: "pre",
          whatsapp: "",
          rg: "",
          company_id: "",
          photo: "",
          id_dep: "",
          genre: "",
          schooling: "",
          holder_income: "",
          working_department: "",
          marital_status: "",
          spouse_name: "",
          spouse_birthday: "",
          father_name: "",
          mother_name: "",
        });

        setTextAlert(response.data.message);
        if (response.data) {
          navigate("/cadastro/5");
        }
      }
    } catch (err) {
      setTextAlert(err.response.data.message);
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <>
      <Container>
        <HeaderCadastro>
          <Icon>
            <ChevronLeft
              color="#FFF"
              onClick={() => {
                navigate("/cadastro/3");
              }}
            />
          </Icon>
          <img
            src={logo}
            alt="logo"
            width={120}
            height={80}
          />
          <p>Cadastro</p>
          <div>
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
          </div>
        </HeaderCadastro>
        <Form>
          <p>Por favor cadastre abaixo uma senha de acesso de 4 números:</p>
          <div>
            <OtpInput
              isInputNum={true}
              numInputs={4}
              separator={" "}
              value={register.password}
              onChange={(e) => {
                setRegister({ ...register, password: e });
              }}
              inputStyle={{
                width: "3rem",
                marginLeft: "0.25rem",
                marginRight: "0.25rem",
                height: "3rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid #ccc",
              }}
            />
          </div>
        </Form>
        <Button>
          <SecondButton
            text="Concluir Cadastro"
            funcao={HandleSubmit}
          />
        </Button>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={textAlert}
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
      </Container>
    </>
  );
}
