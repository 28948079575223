import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  min-height:100%;
  transition: 5s ease-in;
`;

export const Logo = styled.div`
  position: absolute;
  left: 10px;
  top: 5px;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const InfoProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding-top: 40px;

  img {
    border-radius: 50%;
    border: 3px solid #08559f;
    object-fit: cover;
  }

  h4 {
    margin-top: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    color: #08559f;
  }

  label {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    color: #999;
    font-weight: 500;
    margin-top: 2px;
    letter-spacing: 1px;
  }
`;
export const DivHR = styled.div`
  margin: 30px 30px 0px 30px;
`;

export const MenuOptions = styled.div`
  width: 100%;
  height: auto;
`;

export const DivMap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 14px 30px 14px 30px;
  border-bottom: 1px solid #fafafa;
  margin: 0px 0px;

  p {
    font-family: Montserrat, sans-serif;
    font-size: 17px;
    color: #08559f;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
  }
`;

export const Alert = styled.div`
  width: "100%";
  display: "flex";
  justify-content: "center";
`;

export const TextBlocked = styled.p`
  font-size: 12px;
  font-weight: bolder;
  text-align: center;
  color: #ea5455;
  line-height: 12.19px;
  padding: 10px 20px 0px 20px;
`;

export const ProfileImageBlocked = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 50%;
    border: 5px solid #ea5455;
  }

  p {
    padding: 0px 5px;
    margin-top: -5px;
    font-size: 12px;
    background-color: #ea5455;
    color: #fff;
    border-radius: 10px;
    border: 5px solid #ea5455;
  }
`;
