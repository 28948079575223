import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../components/PrimaryButton";
import CustomAlert from "../../../../components/Alerts/CustomAlert";
import TextField from "@mui/material/TextField";
import logo_default from "../../../../assets/logo_default.png";
import CircularProgress from "@mui/material/CircularProgress";
import { MdModeEditOutline } from "react-icons/md";
import { Container, Form, DivButton, Image, DivLoading, Alert } from "./style";
import api from "../../../../Services/index";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import Telefone from "../../../../components/Inputs/telephone";
import * as moment from "moment";

export default function PersonalData() {
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadFoto, setLoadFoto] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const { setComponents, components, setDataClient, dataClient } =
    StateGlobal();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dataNasc, setDataNasc] = useState("");

  async function handleGetInfo() {
    try {
      setLoading(true);
      const response = await api.get("/api/user");
      setName(response.data.name);
      setPhone(response.data.phone);
      setEmail(response.data.email);
      setDataNasc(response.data.birthday);
      setIsDisabled(true);
      setTimeout(() => {
        setLoading(false);
        setLoadingImg(false);
      }, [500]);
    } catch (err) {}
  }

  async function handleUpdateInfo() {
    var data = moment(dataNasc, "DD/MM/YYYY");

    try {
      setLoading(true);
      const response = await api.put("/api/user/update", {
        name,
        phone: phone?.replace(/[^0-9s]/g, ""),
        birthday: data.format("YYYY-MM-DD"),
      });

      if (response) {
        setAlertType("success");
        setShowAlertOk(true);
        setLoading(false);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  useEffect(() => {
    handleGetInfo();
  }, []);

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function getBase64(file) {
    if (file.size > 10000000) {
      alert(
        "Documento maior que 10MB, por favor verifique o tamanho do arquivo."
      );
      return;
    }

    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      putFotoPerfil(reader.result);
    };
  }

  async function putFotoPerfil(image) {
    setLoadingImg(true);
    setLoadFoto(true);
    try {
      const response = await api.post("/api/user/picture", { photo: image });
      if (response.data) {
        setDataClient({ ...dataClient, photo: response.data.picture_url });
        localStorage.setItem("attPhoto", true);
        window.location.reload(true);
      }
    } catch (err) {
      setLoadingImg(false);
    }
  }

  var newDate = dataNasc?.split("/")?.reverse()?.join("-");

  function dateMask(value) {
    value = value?.replace(/\D/g, "");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");
    value = value?.replace(/(\d{2})(\d)/, "$1/$2");

    return value;
  }

  return (
    <>
      {loading ? (
        <Container>
          <DivLoading>
            <CircularProgress style={{ marginTop: "30px" }} />
          </DivLoading>
        </Container>
      ) : (
        <Container>
          <div id="sub-container">
            {loadingImg === true ? (
              <Image>
                <img src={logo_default} width={80} height={80} alt="2" />
              </Image>
            ) : (
              <Image>
                <img
                  src={
                    dataClient.photo !== null ? dataClient.photo : logo_default
                  }
                  width={80}
                  height={80}
                  alt="2"
                />
              </Image>
            )}
            <div id="div-pen">
              <label htmlFor="addFoto">
                {loadFoto ? (
                  <div style={{ paddingTop: "2px" }}>
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  </div>
                ) : (
                  <MdModeEditOutline fill="#fff" />
                )}
                <input
                  id="addFoto"
                  type="file"
                  hidden
                  onChange={(event) => getBase64(event.target.files[0])}
                />
              </label>
            </div>
          </div>
          <Form>
            <label id="name">Nome Completo</label>
            <TextField
              id="name"
              type="text"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label id="telefone">Telefone</label>
            <TextField
              id="telefone"
              size="small"
              value={Telefone(phone)}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label id="email">E-mail</label>
            <TextField
              id="email"
              type="text"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isDisabled}
            />
            <label id="dataNasc">Data de Nascimento</label>
            <TextField
              id="dataNasc"
              size="small"
              value={dateMask(dataNasc)}
              onChange={(e) => setDataNasc(e.target.value)}
            />
          </Form>
          <DivButton>
            <PrimaryButton
              text="Atualizar informações"
              funcao={handleUpdateInfo}
            />
          </DivButton>
          <Alert>
            <CustomAlert
              type={alertType}
              alertText={
                alertType === "success"
                  ? "Dados atualizados com sucesso!"
                  : "Erro!"
              }
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />
          </Alert>
        </Container>
      )}
    </>
  );
}
