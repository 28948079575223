import React, { useContext, useEffect, useState } from "react";
import logo from "../../../assets/LogoCadastro.png";

import PrimaryButton from "../../../components/PrimaryButton";
import TextField from "@mui/material/TextField";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";
import { RegisterContext } from "../../../Utility/Context/RegisterContext";
import CustomAlert from "../../../components/Alerts/CustomAlert";

import {
  Container,
  Step1,
  Step2,
  Step3,
  Step4,
  HeaderCadastro,
  Form,
  Button,
  Fields,
  Icon,
  FieldsAndButton,
  Alert,
} from "./style";

export default function Cadastro3() {
  const navigate = useNavigate();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const { register, setRegister } = useContext(RegisterContext);

  const searchPostalCode = async (postalCode) => {
    fetch(`https://viacep.com.br/ws/${register.cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        setRegister({
          ...register,
          cep: data.cep,
          address: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
        });
      });
  };

  useEffect(() => {
    register.cep.length === 8 && searchPostalCode();
  }, [register.cep]);

  function handleNextPage() {
    if (
      register.cep !== "" &&
      register.address !== "" &&
      register.neighborhood !== "" &&
      register.number !== "" &&
      register.complement !== "" &&
      register.city !== "" &&
      register.state !== ""
    ) {
      navigate("/cadastro/4");
    } else {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <>
      <Container>
        <HeaderCadastro>
          <Icon>
            <ChevronLeft
              color="#FFF"
              onClick={() => {
                navigate("/cadastro/2");
              }}
            />
          </Icon>
          <img src={logo} alt="logo" width={120} height={80} />
          <p>Cadastro</p>
          <div>
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
          </div>
        </HeaderCadastro>
        <Form>
          <label id="cep">CEP</label>

          <TextField
            id="cep"
            size="small"
            type="tel"
            placeholder="Digite seu CEP"
            value={register.cep}
            onChange={(e) => setRegister({ ...register, cep: e.target.value })}
            inputProps={{ maxLength: 8 }}
          />
          <label id="endereco">Endereço</label>
          <TextField
            id="endereco"
            type="text"
            size="small"
            // placeholder="Por favor digite seu nome completo"
            value={register.address}
            onChange={(e) =>
              setRegister({ ...register, address: e.target.value })
            }
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Fields>
              <label id="bairro">Bairro</label>
              <TextField
                id="bairro"
                type="text"
                size="small"
                // placeholder="Por favor digite seu nome completo"
                value={register.neighborhood}
                onChange={(e) =>
                  setRegister({ ...register, neighborhood: e.target.value })
                }
              />
            </Fields>
            <Fields style={{ marginLeft: "15px" }}>
              <label id="numero">Número</label>
              <TextField
                id="numero"
                type="number"
                size="small"
                // placeholder="Por favor digite seu nome completo"
                value={register.number}
                onChange={(e) =>
                  setRegister({ ...register, number: e.target.value })
                }
              />
            </Fields>
          </div>
          <label id="complemento">Complemento</label>
          <TextField
            size="small"
            id="complemento"
            type="text"
            value={register.complement}
            onChange={(e) =>
              setRegister({ ...register, complement: e.target.value })
            }
          />
          <FieldsAndButton>
            <Fields>
              <label id="cidade">Cidade</label>
              <TextField
                id="cidade"
                type="text"
                size="small"
                // placeholder="Por favor digite seu nome completo"
                value={register.city}
                onChange={(e) =>
                  setRegister({ ...register, city: e.target.value })
                }
              />
            </Fields>
            <Fields style={{ marginLeft: "15px" }}>
              <label id="estado">Estado</label>
              <TextField
                id="estado"
                type="text"
                size="small"
                // placeholder="Por favor digite seu nome completo"
                value={register.state}
                onChange={(e) =>
                  setRegister({ ...register, state: e.target.value })
                }
              />
            </Fields>
          </FieldsAndButton>
        </Form>
        <Button>
          <PrimaryButton text="Próximo" funcao={handleNextPage} />
        </Button>
        <Alert>
          <CustomAlert
            type={alertType}
            alertText={alertType === "error" && "Preencha todos os campos!"}
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </Alert>
      </Container>
    </>
  );
}
