import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
`;

export const DivImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const Form = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-family: Montserrat, sans-serif;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.div`
  position: absolute;
  width: 100%;
  padding: 0px 30px 0px 30px;
  bottom: 130px;
`;

export const ButtonTwo = styled.div`
  position: absolute;
  width: 100%;
  padding: 0px 30px 0px 30px;
  bottom: 65px;
`;

export const Alert = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
