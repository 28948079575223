export const saveToken = (token) => {
  localStorage.setItem("token", token);
};

export const savePublicKey = (data) => {
  localStorage.setItem("public_key", data);
};

export const getPublicKey = () => {
  return localStorage.getItem("public_key");
};

export const removePublicKey = () => {
  return localStorage.removeItem("public_key");
};

export const saveCheckoutID = (data) => {
  localStorage.setItem("checkout_id", data);
};

export const getCheckoutID = () => {
  return localStorage.getItem("checkout_id");
};

export const removeCheckoutID = () => {
  return localStorage.removeItem("checkout_id");
};

export const saveCheckoutSession = (data) => {
  localStorage.setItem("checkout_session", data);
};

export const getCheckoutSession = () => {
  return localStorage.getItem("checkout_session");
};

export const removeCheckoutSession = () => {
  return localStorage.removeItem("checkout_session");
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  return localStorage.removeItem("token");
};

export const localUser = (data) => {
  return localStorage.setItem("user", JSON.stringify(data));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const isAuthenticated = () => localStorage.getItem("token") !== null;
