import styled from "styled-components";

export const Header = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 0px 15px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #00000030;
  z-index: 99;
  h3 {
    color: #5e5873;
  }

  p {
    text-decoration: underline;
    color: #08559f;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Alert = styled.div`
  position: absolute;
  width: 100%;
  padding: 0px 30px 0px 30px;
  bottom: 140px;
`;
