import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Button,
  DivImage,
  Fields,
  FieldsAndButton,
  Alert,
} from "./style";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../../components/PrimaryButton";
import { StateGlobal } from "../../../../Utility/Context/RegisterContext";
import api from "../../../../Services/index";
import CustomAlert from "../../../../components/Alerts/CustomAlert";

import Logo from "../../../../assets/Logo.png";
import ModalExitDependent from "../../../../components/ModalExitDependent";

export default function EditDependents2({ setStep }) {
  const { dependentInfo, addressInfo, setAddressInfo, ModalExitIsTrue } =
    StateGlobal();
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [loading, setLoading] = useState(false);

  const addressId = dependentInfo.addresses[0].id;

  useEffect(() => {
    addressInfo.zip_code.length === 8 && searchPostalCode();
  }, [addressInfo.zip_code]);

  async function handleGetInfo() {
    try {
      setLoading(true);
      setAddressInfo({
        address_id: dependentInfo.addresses[0].id,
        zip_code: dependentInfo.addresses[0].zip_code,
        state: dependentInfo.addresses[0].state,
        city: dependentInfo.addresses[0].city,
        street: dependentInfo.addresses[0].street,
        number: dependentInfo.addresses[0].number,
        neighborhood: dependentInfo.addresses[0].neighborhood,
        complement: dependentInfo.addresses[0].complement,
        type_address: "principal",
      });
      setLoading(false);
    } catch (err) {}
  }

  const searchPostalCode = async (postalCode) => {
    fetch(`https://viacep.com.br/ws/${addressInfo.zip_code}/json/`)
      .then((response) => response.json())
      .then((data) => {
        setAddressInfo({
          ...addressInfo,
          zip_code: data.cep,
          state: data.uf,
          city: data.localidade,
          street: data.logradouro,
          neighborhood: data.bairro,
        });
      });
  };

  useEffect(() => {
    handleGetInfo();
  }, []);

  async function HandleSubmit() {
    try {
      setLoading(true);
      const response = await api.put(
        `/api/user/dependent/address/update/${addressId}`,
        {
          address_id: addressInfo.address_id,
          zip_code: addressInfo.zip_code?.replace(/[^0-9s]/g, ""),
          state: addressInfo.state,
          city: addressInfo.city,
          street: addressInfo.street,
          number: addressInfo.number,
          neighborhood: addressInfo.neighborhood,
          complement: addressInfo.complement,
          type_address: "principal",
        }
      );

      if (response) {
        setAlertType("success");
        setShowAlertOk(true);
        setLoading(false);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertError(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  return (
    <Container>
      <DivImage>
        <img src={Logo} alt="logo" width={180} />
      </DivImage>
      <Form>
        <label id="cep">CEP</label>

        <TextField
          type="tel"
          id="cep"
          size="small"
          value={addressInfo.zip_code}
          onChange={(e) => {
            setAddressInfo({ ...addressInfo, zip_code: e.target.value });
          }}
        />
        <label id="endereco">Endereço</label>
        <TextField
          id="endereco"
          type="text"
          size="small"
          value={addressInfo.street}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Fields>
            <label id="bairro">Bairro</label>
            <TextField
              id="bairro"
              type="text"
              size="small"
              value={addressInfo.neighborhood}
            />
          </Fields>
          <Fields style={{ marginLeft: "15px" }}>
            <label id="numero">Número</label>
            <TextField
              id="numero"
              type="number"
              size="small"
              value={addressInfo.number}
              onChange={(e) => {
                setAddressInfo({ ...addressInfo, number: e.target.value });
              }}
            />
          </Fields>
        </div>
        <label id="complemento">Complemento</label>
        <TextField
          size="small"
          id="complemento"
          type="text"
          value={addressInfo.complement}
          onChange={(e) => {
            setAddressInfo({ ...addressInfo, complement: e.target.value });
          }}
        />
        <FieldsAndButton>
          <Fields>
            <label id="cidade">Cidade</label>
            <TextField
              id="cidade"
              type="text"
              size="small"
              value={addressInfo.city}
            />
          </Fields>
          <Fields style={{ marginLeft: "15px" }}>
            <label id="estado">Estado</label>
            <TextField
              id="estado"
              type="text"
              size="small"
              value={addressInfo.state}
            />
          </Fields>
        </FieldsAndButton>
        {ModalExitIsTrue === true ? (
          <ModalExitDependent
            open={ModalExitIsTrue}
            HandleSubmit={HandleSubmit}
          />
        ) : (
          ""
        )}
      </Form>
      <Button>
        <PrimaryButton text="Atualizar informações" funcao={HandleSubmit} />
      </Button>
      <Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Informações atualizadas com sucesso!"
              : "Erro ao atualizar informações!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </Alert>
    </Container>
  );
}
